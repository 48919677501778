import { attr, Model } from "redux-orm";

import ENTITY_NAME from "../entities";
import ActionTypes from "./actionTypes";

export class ExportAddressBook extends Model {
  static reducer(action, ExportAddressBookModel) {
    switch (action.type) {
      case ActionTypes.SEARCH.SUCCESS:
        ExportAddressBookModel.all().delete();
        action.payload.results.forEach(exportAddressBook =>
          ExportAddressBookModel.upsert(exportAddressBook)
        );
        break;
      case ActionTypes.GET_EXPORTER_ADDRESS_BOOK.SUCCESS:
        ExportAddressBookModel.withId(
          action.payload.exportAddressBookId
        ).update(action.payload);
        break;
    }
  }

  static modelName = ENTITY_NAME.EXPORT_ADDRESS_BOOK;

  static fields = {
    exportAddressBookId: attr(),
  };

  static options = {
    idAttribute: "exportAddressBookId",
  };
}
