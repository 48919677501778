import api from "./api";

export const fetchAddressBooks = (query, options) =>
  api.get({
    path: "/addressbook",
    query,
    options,
  });

export const fetchAddressBookById = (id, type) =>
  api.get({
    path: `/addressbook/${id}?addressBookType=${type}`,
  });

export const deleteAddressBookById = (id, query, options) =>
  api.delete({
    path: `/addressbook/${id}`,
    query,
    options,
  });

export const deleteAddressBook = (query, options) =>
  api.delete({
    path: "/addressbook",
    query,
    options,
  });

export const updateAddressBook = (id, query, body) =>
  api.put({
    path: `/addressbook/${id}`,
    query,
    body,
  });

export const insertAddressBook = body =>
  api.post({
    path: "/addressbook",
    body,
  });

export const importAddressBook = (body, query) =>
  api.sendFormData({
    path: "/addressbook/import",
    body,
    query,
  });
