// Address book menu
export const ADDRESS_BOOK = "Address book";
export const GROUPS = "Groups";
export const PRODUCTS = "Products";
export const EXPORTS = "Exports";

export const SEARCH = "Search";
export const FILTER = "Filter";
export const RESET = "Reset";
export const COLLECTIONS = "Collections";

// Titles
export const ADDRESS_LOOKUP = "Address Lookup";
export const PENDING = "Pending";
export const FAILED = "Failed";
export const EDIT_ADDRESS = "Edit Address";
export const ADDRESS_DETAILS = "Address Details";
export const SEARCH_AND_SELECT_ADDRESS = "Search & Select Address";
export const VALIDATION_AND_ASSOCIATED_GROUPS =
  "Validation & Associated Groups";
export const CREATE_ADDRESS = "Create Address";
export const ALL_FIELDS_MARKED_REQUIRED =
  "All Fields Marked With * Are Required";

// Labels
export const GROUP_NAME = "Group Name";
export const AVAILABLE_SERVICES = "Available Services";
export const CANCEL = "Cancel";
export const CONFIRM = "Confirm";
export const OK = "Ok";
export const YES = "Yes";
export const NO = "No";
export const SAVE = "Save";
export const DELETE = "Delete";
export const DELETE_ALL = "Delete All";
export const NEW = "New";
export const SHORT_NAME = "Short Name";
export const COUNTRY = "Country";
export const LINE = "Line";
export const ERROR = "Error";
export const ERRORS = "Errors";
export const INSERTED = "Inserted";
export const WARNING = "Warning";
export const WARNINGS = "Warnings";
export const ISSUES = "Issues";
export const STATUS = "Status";
export const POSTCODE = "Postcode";
export const STREET = "Street";
export const LOCALITY = "Locality";
export const ORGANISATION = "Organisation";
export const ADDRESS_LINE_1 = "Address Line 1";
export const ADDRESS_LINE_2 = "Address Line 2";
export const TOWN = "Town";
export const COUNTY = "County";
export const CONTACT = "Contact";
export const TELEPHONE = "Telephone";
export const EMAIL = "Email";
export const MOBILE = "Mobile";
export const DELIVERY_INSTRUCTIONS = "Delivery Instructions";
export const REF_1 = "Ref 1";
export const REF_2 = "Ref 2";
export const REF_3 = "Ref 3";
export const VALID = "Valid";
export const DESTINATION_COUNTRY = "Destination Country";
export const POSTAL_ZIP_CODE = "Postal / Zip Code";
export const ORGANISATION_NAME = "Organisation / Name";
export const CITY = "Town / City";
export const COUNTY_STATE = "County / State";
export const CONTACT_NAME = "Contact Name";
export const CONTACT_TELEPHONE = "Contact Telephone";
export const ADDRESS_TYPE = "Address Type";
export const NOTIFICATION_EMAIL = "Notification Email";
export const MOBILE_NOTIFICATION_TEXT = "Mobile Notification Text";
export const DELIVERY_INFORMATION = "Delivery Information";
export const YOUR_REFERENCE_1 = "Your Reference 1";
export const YOUR_REFERENCE_2 = "Your Reference 2";
export const YOUR_REFERENCE_3 = "Your Reference 3";
export const VAT_NUMBER = "Vat Number";
export const EORI_NUMBER = "EORI Number";
export const PID_NUMBER = "PID Number";
export const UKIMS_NUMBER = "UKIMS Number";
export const CONSUMER = "Consumer";
export const BUSINESS = "Business";
export const IMPORTER_TYPE = "Importer Type";
export const AT_RISK = "Parcel At Risk";
export const DELETE_RECORD = "Delete Record";
export const DESTINATION = "Destination";
export const IMPORT = "Import";
export const DEFAULT = "Default";
export const ADDRESS_BOOK_IMPORT = "address book import";
export const ADDRESS_BOOK_EXPORT = "Export Address Book";
export const ADDRESS_BOOK_IMPORT_RESULT = "Address Book Import Results";
export const IMPORT_OPTIONS = "import options";
export const GROUP_IMPORT_BEHAVIOUR = "Group Import Behaviour";
export const UPDATE = "Update";
export const UPLOAD = "Upload";
export const EXPORT = "Export";
export const UPLOADING_ADDRESS_BOOK = "Uploading address book...";
export const EXPORTING_ADDRESS_BOOK = "Exporting address book...";
export const DATE_CREATED = "Date Created";
export const DATE_UPDATED = "Date Updated";
export const OWNER = "Owner";
export const TEMPLATE = "Template";
export const FILE_MUST_BE_SELECTED = "File must be selected";
export const TEMPLATE_MUST_BE_SELECTED = "Template must be selected";
export const IMPORT_NEW_ONLY = "import new only (reject duplicates)";
export const OVERWRITE_ADDRESSES = "overwrite existing addresses";
export const EMPTY_ADDRESS = "empty address book before import";
export const FILE_LOCATION = "File Location";
export const HIDE_DELIVERY_ADDRESS = "Hide Delivery Addresses";
export const DEFAULT_ADDRESS = "Default Address";
export const ADDRESS_VALID = "Address Valid";
export const LAST_UPDATED = "Last Updated";
export const SEARCH_LABEL = "Search For";
export const PLEASE_SELECT_ADDRESS_BOOK =
  "Please select which address book type you would like to export below";

// Messages
export const IN_GROUP_ADDRESSES = "In Group Addresses";
export const CREATE_NEW_GROUP = "Create New Group";
export const SELECT_GROUP = "Select Group";
export const CANCEL_GROUP_EDIT = "Cancel Group Edit";
export const CONFIRM_YOUR_CANCEL = "Confirm Your Cancel";
export const ARE_YOU_SURE_YOU_WISH_TO_CANCEL =
  "Are you sure you wish to cancel?";
export const ARE_YOU_SURE_YOU_WISH_TO_DELETE_GROUP =
  "Are you sure you wish to delete group?";
export const CONFIRM_ADDRESS_BOOK_DELETION = "Confirm Address Book Deletion";
export const CONFIRM_GROUP_DELETION = "Confirm Group Deletion";
export const THERE_IS_NO_WAY_TO_RECOVER =
  "There is no way to recover your address book data once you’ve confirmed this action";
export const CONFIRM_ADDRESS_DELETION = "Confirm Address Deletion";
export const ARE_YOU_SURE_TO_DELETE_ADDRESSBOOK_ENTRY =
  "Are you sure you wish to delete this entry in your address book?";
export const ARE_YOU_SURE_TO_DELETE_ALL_ADDRESSBOOK =
  "Are you sure you wish to delete all entries in your address book?";
export const SHORT_NAME_MUST_BE_ENTERED = "Short Name must be entered";
export const TELEPHONE_MUST_BE_VALID =
  "Telephone must be a valid Telephone number";
export const TOWN_CITY_MUST_BE_ENTERED = "Town/City must be entered";
export const NOTIFICATION_EMAIL_MUST_BE_VALID =
  "Notification Email must be a valid email address";
export const NOTIFICATION_TEXT_MUST_BE_VALID =
  "Notification Text must be a valid Telephone number";
export const POSTCODE_MUST_BE_ENTERED = "Postcode must be entered";
export const POSTCODE_MUST_BE_VALID = "Postcode must be a valid Postcode";
export const GROUP_NAME_MUST_BE_ENTERED = "Group Name must be entered";
export const GROUP_NAME_MAX = "Group Name must be maximum of 35 characters";
export const NETWORK_MUST_BE_ENTERED = "Network must be entered";
export const STREET_MUST_BE_ENTERED = "Street must be entered";
export const CONTACT_NAME_MUST_BE_ENTERED = "Contact Name must be entered";
export const DESTINATION_COUNTRY_MUST_BE_ENTERED =
  "Destination country must be entered";
export const ADDRESS_TYPE_MUST_BE_SELECTED = "Address Type must be selected";
export const EORI_MIN_ERROR_MESSAGE = "EORI No must be more than 4 characters";
export const INVALID_COUNTRY_ENTERED = "You’ve entered an invalid country name";
export const ENTER_OR_SELECT_DESTINATION_COUNTRY =
  "Enter or select the destination country";
export const ENTER_SHORT_NAME = "Enter your address book short name";
export const UPLOAD_FILE_CHANGED =
  "File has been changed. Please try to select again.";
export const EXPORT_ADDRESS_BOOK_STARTED_PLEASE_USE =
  "Export Started, Please Use The Exports Tab To See\nProgress And Download Your CSV File.";
export const EXPORT_IS_ALREADY_IN_PROGRESS_PLEASE_USE =
  "Export Is Already In Progress. Please Use The Exports\nTab To See Progress And Download Your CSV File.";

// Hints
export const MAX_15_CHARACTERS = "Max 15 characters";
export const MAX_25_CHARACTERS = "Max 25 characters";
export const MAX_35_CHARACTERS = "Max 35 characters";
export const MAX_32_CHARACTERS = "Max 32 characters";
export const MAX_45_CHARACTERS = "Max 45 characters";
export const MAX_50_CHARACTERS = "Max 50 characters";
export const MAX_100_CHARACTERS = "Max 100 characters";
export const GB = "GB";
export const IE = "IE";
export const UNITED_KINGDOM = "UNITED KINGDOM";
export const UPPERCASE = "uppercase";

// Snackbar messages
export const FAILED_TO_FETCH = "Failed to fetch";
export const SOMETHING_WENT_WRONG = "Something went wrong";
export const FAILED_TO_FETCH_ERROR_MESSAGE_$ = (failedToFetchEntityName = "") =>
  `${FAILED_TO_FETCH} ${failedToFetchEntityName}`;
export const COUNTRIES = "countries";
export const GROUP = "group";
export const GROUP_ADDRESSES = "group addresses";
export const ADDRESS_BOOK_GROUPS = "address book groups";
export const ADDRESS_BOOKS = "address books";
export const POSTCODES = "postcodes";
export const ADDRESS_BOOK_WAS_UPDATED = "Record has been updated";
export const ADDRESS_BOOK_WAS_CREATED = "1 New record has been created";
export const GROUP_WAS_UPDATED = "Group has been updated";
export const GROUP_WAS_DELETED = "Group has been deleted";
export const GROUP_WAS_SAVED = "Group has been saved";
export const ADDRESSES_DELETED = "Addresses Deleted";
export const NETWORKS = "networks";
export const ADDRESSBOOK_NOT_FOUND = "Addressbook not found";
export const PLEASE_COMPLETE_ALL_REQUIRED_FIELDS =
  "Please complete all required fields as indicated *";
export const $_MAXIMUM_OF_$ = (fieldName, limit) =>
  `${fieldName} must be a maximum of ${limit} characters`;
export const $_EQUAL_TO_$ = (fieldName, limit) =>
  `${fieldName} must be equal to ${limit} characters`;

// Joi Messages
export const joiMessages = {
  any: {
    required: "{{label}} must be entered",
  },
  string: {
    max: "{{label}} must be a maximum of {{limit}} characters",
    min: "{{label}} must be more than {{limit}} characters",
    empty: "{{label}} must be entered",
    regex: {
      name: "A valid {{label}} must be entered",
    },
  },
  number: {
    base: "{{label}} must be a number",
    min: "{{label}} must be more than {{limit}}",
    max: "{{label}} must be a maximum of {{limit}}",
    greater: "{{label}} must be equal or greater than {{limit}}",
    less: "{{label}} must be equal or less than {{limit}}",
  },
};
