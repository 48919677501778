import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { apisClient } from "~/apis";

export const ActionTypes = createActionTypes("REFERENCE_DATA", {
  FETCH_COUNTRIES: createAsyncActionTypes("FETCH_COUNTRIES"),
  FETCH_NETWORKS: createAsyncActionTypes("FETCH_NETWORKS"),
  FETCH_ADDRESSES: createAsyncActionTypes("FETCH_ADDRESSES"),
});

export const fetchCountries = createAsyncAction(
  () => apisClient.reference.fetchCountries(),
  ActionTypes.FETCH_COUNTRIES
);

export const fetchNetworks = createAsyncAction(
  (query, fetchOptions) =>
    apisClient.reference.fetchNetworkList(query, fetchOptions),
  ActionTypes.FETCH_NETWORKS
);
