import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { reduxForm, propTypes as reduxPropTypes, reset } from "redux-form";

import { withNotifier } from "@dpdgroupuk/mydpd-app";
import { Button, withSnackbar } from "@dpdgroupuk/mydpd-ui";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import Step from "~/components/Step";
import {
  ADDRESS_GROUP_ENTRY_PAGE,
  CONFIRM_CANCEL_POP_UP,
} from "~/constants/analytics";
import { CREATE_ADDRESS_BOOK_GROUP } from "~/constants/forms";
import {
  ARE_YOU_SURE_YOU_WISH_TO_CANCEL,
  CANCEL,
  CONFIRM_YOUR_CANCEL,
  CREATE_NEW_GROUP,
  GROUP_WAS_SAVED,
  SAVE,
} from "~/constants/strings";
import withPromptAnalytics from "~/hocs/withPromptAnalytics";
import { groupsValidators } from "~/models/validators";
import { GroupsActions } from "~/redux/orm/Groups";
import { ReferenceDataSelectors } from "~/redux/reference";
import { getDeepKeys } from "~/utils/object";

import CardStepFooter from "../../components/CardStepFooter";
import GroupForm from "../../components/GroupForm/GroupForm";

const CreateGroup = props => (
  <Step step={2} title={CREATE_NEW_GROUP}>
    <GroupForm networks={props.networks} />
    <CardStepFooter>
      <Button variant="dark" onClick={props.onCancel}>
        {CANCEL}
      </Button>
      <Button
        disabled={props.pristine || !props.valid || props.submitting}
        onClick={props.handleSubmit}
      >
        {SAVE}
      </Button>
    </CardStepFooter>
  </Step>
);

CreateGroup.propTypes = {
  networks: PropTypes.array,
  history: PropTypes.object,
  valid: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  ...reduxPropTypes,
};

export default compose(
  withPromptAnalytics,
  withNotifier,
  withSnackbar,
  withRouter,
  connect(
    state => ({
      networks: ReferenceDataSelectors.getNetworksKeyValue(state),
    }),
    (dispatch, { history, analyticsPrompt, snackbar, notifier }) => ({
      onCancel: () =>
        analyticsPrompt.showConfirmation({
          header: CONFIRM_YOUR_CANCEL,
          message: ARE_YOU_SURE_YOU_WISH_TO_CANCEL,
          trackProps: CONFIRM_CANCEL_POP_UP,
          onConfirm: () => {
            history.goBack();
          },
        }),
      onSubmit: notifier.runAsync(async values => {
        await dispatch(
          GroupsActions.createGroup({
            ...values,
            groupName: values.groupName.trim(),
          })
        );

        await dispatch(reset(CREATE_ADDRESS_BOOK_GROUP));

        snackbar.showSuccess({ message: GROUP_WAS_SAVED });
        // TODO: add analytics for GROUP_SAVE_SUCCESSFUL_POP_UP
      }),
    })
  ),
  reduxForm({
    form: CREATE_ADDRESS_BOOK_GROUP,
    enableReinitialize: true,
    validate: groupsValidators.groupValidator,
    onSubmitFail: (errors, dispatch, _, props) => {
      const mappedErrors = getDeepKeys(errors);
      props?.notifier.scrollToError(mappedErrors);
    },
  }),
  withTrackProps({
    onCancel: ADDRESS_GROUP_ENTRY_PAGE.CLICK_CANCEL,
    handleSubmit: ADDRESS_GROUP_ENTRY_PAGE.CLICK_SAVE,
  })
)(CreateGroup);
