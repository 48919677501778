import { createAsyncAction } from "@dpdgroupuk/redux-action-creator";

import { addressBookGroupApi } from "~/apis";
import { Fields } from "~/constants/forms";

import ActionTypes from "./actionTypes";

export const fetchGroups = createAsyncAction(
  fetchOptions => addressBookGroupApi.fetchAddressBookGroups(fetchOptions),
  ActionTypes.FETCH
);

export const fetchGroupById = createAsyncAction(async (id, fetchOptions) => {
  const { data } = await addressBookGroupApi.fetchAddressBookGroupById(
    id,
    fetchOptions
  );
  return data;
}, ActionTypes.GET);

export const deleteGroupById = createAsyncAction(async (id, fetchOptions) => {
  await addressBookGroupApi.removeAddressBookGroupById(id, fetchOptions);
  return { id };
}, ActionTypes.DELETE);

export const createGroup = createAsyncAction(async (body, fetchOptions) => {
  const { data } = await addressBookGroupApi.createAddressBookGroup(
    body,
    fetchOptions
  );
  return { ...data, ...body };
}, ActionTypes.CREATE);

export const updateGroup = createAsyncAction(
  async (groupId, body, fetchOptions) => {
    const { groupName, networkCode } = body;
    await addressBookGroupApi.editAddressBookGroup(
      groupId,
      { groupName, networkCode },
      fetchOptions
    );
    return { groupId, body };
  },
  ActionTypes.UPDATE
);

export const fetchGroupAddressesById = createAsyncAction(
  async (groupId, query, fetchOptions) => {
    const data = await addressBookGroupApi.fetchAddressBookGroupAddresses(
      groupId,
      { ...query, searchSort: Fields.SHORT_NAME },
      fetchOptions
    );
    return { groupId, ...data };
  },
  ActionTypes.FETCH_ADDRESSES
);
