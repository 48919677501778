import { isEmpty, set } from "lodash";
import { attr, Model } from "redux-orm";

import { AddressBookEntity, AddressBookImportFields } from "~/constants/forms";
import { phoneNumberNormalize } from "~/models/normalizers";
import { getValue } from "~/utils/object";

import ENTITY_NAME from "../entities";
import ActionTypes from "./actionTypes";

export const preparingImportRequest = params => {
  // eslint-disable-next-line no-undef
  const formData = new FormData();
  const query = {
    [AddressBookImportFields.TEMPLATE]:
      params[AddressBookImportFields.TEMPLATE],
    [AddressBookImportFields.IMPORT_OPTIONS]:
      params[AddressBookImportFields.IMPORT_OPTIONS],
  };
  if (!isEmpty(params[AddressBookImportFields.GROUP_IMPORT])) {
    query[AddressBookImportFields.GROUP_IMPORT] =
      params[AddressBookImportFields.GROUP_IMPORT];
  }
  formData.append(
    AddressBookImportFields.ADDRESS_BOOK_FILE,
    params[AddressBookImportFields.ADDRESS_BOOK_FILE]
  );
  return {
    formData,
    query,
  };
};

const phoneFields = [
  AddressBookEntity.CONTACT_DETAILS.TELEPHONE,
  AddressBookEntity.NOTIFICATION_DETAILS.MOBILE,
];

export class AddressBook extends Model {
  static reducer(action, AddressBookModel) {
    switch (action.type) {
      case ActionTypes.SEARCH.SUCCESS:
        AddressBookModel.all().delete();
        action.payload.results.forEach(address => {
          for (const fieldPath of phoneFields) {
            set(
              address,
              fieldPath,
              phoneNumberNormalize(getValue(address, fieldPath, ""))
            );
          }
          AddressBookModel.upsert(address);
        });
        break;
      case ActionTypes.FETCH.SUCCESS:
        for (const fieldPath of phoneFields) {
          set(
            action.payload,
            fieldPath,
            phoneNumberNormalize(getValue(action.payload, fieldPath, ""))
          );
        }
        AddressBookModel.upsert(action.payload);
        break;
      case ActionTypes.DELETE.SUCCESS:
        AddressBookModel.withId(action.payload.addressBookId).delete();
        break;
    }
  }

  static modelName = ENTITY_NAME.ADDRESS_BOOK;

  static fields = {
    addressBookId: attr(),
  };

  static options = {
    idAttribute: "addressBookId",
  };
}
