import Joi from "joi";

import {
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  CONTACT,
  COUNTRY,
  ORGANISATION,
  POSTCODE,
  SHORT_NAME,
  TOWN,
} from "~/constants/strings";
export const shortName = Joi.string().max(45).label(SHORT_NAME);

export const postcode = Joi.string().max(8).label(POSTCODE);

export const organisation = Joi.string().max(35).label(ORGANISATION);

export const countryCode = Joi.string().max(2).label(COUNTRY);

export const street = Joi.string().max(35).label(ADDRESS_LINE_1);

export const locality = Joi.string().max(35).label(ADDRESS_LINE_2);

export const town = Joi.string().max(35).label(TOWN);

export const contactName = Joi.string().max(35).label(CONTACT);
