import { useCallback, useMemo } from "react";

import { chunk, findIndex } from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { useFlexLayout } from "react-table/src/plugin-hooks/useFlexLayout";
import { useRowSelect } from "react-table/src/plugin-hooks/useRowSelect";
import { compose } from "redux";
import { reset } from "redux-form";

import { withAppUser, withNotifier } from "@dpdgroupuk/mydpd-app";
import {
  Button,
  DndTable,
  withOverlay,
  withPrompt,
  withSnackbar,
} from "@dpdgroupuk/mydpd-ui";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import Step from "~/components/Step";
import { ADDRESS_GROUP_ENTRY_PAGE } from "~/constants/analytics";
import { CREATE_ADDRESS_BOOK_GROUP } from "~/constants/forms";
import * as S from "~/constants/strings";
import {
  ARE_YOU_SURE_YOU_WISH_TO_DELETE_GROUP,
  CONFIRM_GROUP_DELETION,
  DELETE,
  GROUP_WAS_DELETED,
  NEW,
  SELECT_GROUP,
  THERE_IS_NO_WAY_TO_RECOVER,
} from "~/constants/strings";
import withPromptAnalytics from "~/hocs/withPromptAnalytics";
import { GroupsSelectors } from "~/redux/orm";
import { ReferenceDataSelectors } from "~/redux/reference";
import { CREATE_GROUP, GROUPS } from "~/router";
import {
  getPathId,
  getQueryFilters,
  getQueryPagination,
  stringifyQuery,
} from "~/utils/query";

import { deleteGroupById } from "../../actions";
import CardStepFooter from "../../components/CardStepFooter";
import styles from "./../../Groups.module.scss";
import { columns } from "./models";

const SelectGroup = props => {
  const { searchPage, searchPageSize, pathname } = useMemo(
    () => ({
      ...getQueryPagination(props.location),
      pathname: props.location.pathname,
    }),
    [props.location]
  );

  const groups = useMemo(
    () => chunk(props.groups, searchPageSize),
    [props.groups, searchPageSize]
  );

  const data = useMemo(() => groups[searchPage - 1], [groups, searchPage]);

  const groupId = useMemo(
    () => getPathId(props.match, "params.groupId"),
    [props.match]
  );

  const selectedId = useMemo(
    () => ({
      [findIndex(data, row => row.groupId === groupId)]: true,
    }),
    [data, groupId]
  );

  const isInvalidRow = useCallback(
    row => !props.networks.includes(row.networkCode),
    [props.networks]
  );

  return (
    <Step
      classes={{
        container: styles.cartContainer,
      }}
      step={1}
      title={SELECT_GROUP}
    >
      <DndTable
        data={data}
        columns={columns}
        classes={{
          container: styles.tableContainer,
          table: styles.table,
          invalidRow: styles.invalidTableRow,
          popover: styles.popover,
        }}
        tableHooks={[useFlexLayout, useRowSelect]}
        initialState={{
          selectedRowIds: selectedId,
        }}
        onClickRow={props.onClickRow}
        selectOnlyOneRow={true}
        isInvalidRow={isInvalidRow}
      >
        <DndTable.DndPaginator
          page={parseInt(searchPage)}
          totalCount={props.groups.length}
          pageSize={parseInt(searchPageSize)}
          onPrevious={props.onPrevious}
          onNext={props.onNext}
          onFirst={props.onFirst}
          onLast={props.onLast}
        />
      </DndTable>
      <CardStepFooter>
        <Button variant="dark" onClick={props.onClickNew}>
          {NEW}
        </Button>
        <Button
          variant="danger"
          disabled={pathname === CREATE_GROUP || !groupId}
          onClick={() => props.onDelete(groupId)}
        >
          {DELETE}
        </Button>
      </CardStepFooter>
    </Step>
  );
};

SelectGroup.propTypes = {
  onFieldEntry: PropTypes.func, // only for analytics
  onSearchChange: PropTypes.func,
  onClickRow: PropTypes.func,
  onDelete: PropTypes.func,
  onPrevious: PropTypes.func,
  onFirst: PropTypes.func,
  onLast: PropTypes.func,
  onClickNew: PropTypes.func,
  onNext: PropTypes.func,
  location: PropTypes.object,
  match: PropTypes.object,
  groups: PropTypes.array,
  networks: PropTypes.array,
};

export default compose(
  withPrompt,
  withPromptAnalytics,
  withRouter,
  withOverlay,
  withAppUser,
  withNotifier,
  withSnackbar,
  connect(
    state => ({
      groups: GroupsSelectors.getAddressBookGroups(state),
      networks: ReferenceDataSelectors.getArrayOfSupportedNetworks(state),
    }),
    (dispatch, { history, location, overlay }) => ({
      resetNavigationState: (options = {}) => {
        history.push({
          pathname: GROUPS,
          search: location.search,
          ...options,
        });
      },
      deleteGroup: async id => {
        overlay.show();
        try {
          await dispatch(deleteGroupById(id));
          overlay.hide();
        } finally {
          overlay.hide();
        }
      },
    })
  ),
  connect(
    null,
    (
      dispatch,
      {
        history,
        location,
        prompt,
        snackbar,
        resetNavigationState,
        deleteGroup,
        notifier,
      }
    ) => {
      const changeFilter = values => {
        const page = getQueryPagination(location);
        const query = getQueryFilters(location);
        history.push({
          search: stringifyQuery({
            ...page,
            ...query,
            ...values,
          }),
        });
      };

      const onNextOrPrev = targetPage => {
        changeFilter({ searchPage: targetPage });
      };

      return {
        onNext: onNextOrPrev,
        onPrevious: onNextOrPrev,
        onFirst: onNextOrPrev,
        onLast: onNextOrPrev,
        onClickRow: (e, { original: { groupId } }) => {
          const { searchPage, searchPageSize } = getQueryPagination(location);
          history.push({
            pathname: `${GROUPS}/${groupId}`,
            search: stringifyQuery({ searchPage, searchPageSize }),
          });
        },
        onClickNew: () => {
          dispatch(reset(CREATE_ADDRESS_BOOK_GROUP));
          history.push({
            pathname: CREATE_GROUP,
            search: location.search,
          });
        },
        onDelete: id => {
          prompt.showConfirmationDelete({
            header: CONFIRM_GROUP_DELETION,
            message: ARE_YOU_SURE_YOU_WISH_TO_DELETE_GROUP,
            footer: THERE_IS_NO_WAY_TO_RECOVER,
            closeButtonText: S.CANCEL,
            confirmButtonText: S.CONFIRM,
            onConfirm: notifier.runAsync(async () => {
              await deleteGroup(id);

              snackbar.showSuccess({ message: GROUP_WAS_DELETED });
              resetNavigationState();

              // TODO: add analytics for DELETE_SUCCESSFUL_POP_UP,
            }),
          });
        },
      };
    }
  ),
  withTrackProps({
    onClickNew: ADDRESS_GROUP_ENTRY_PAGE.CLICK_NEW,
    onDelete: ADDRESS_GROUP_ENTRY_PAGE.CLICK_DELETE,
    onClickRow: ADDRESS_GROUP_ENTRY_PAGE.SELECT_GROUP_LIST,
    onFirst: ADDRESS_GROUP_ENTRY_PAGE.CLICK_FIRST,
    onLast: ADDRESS_GROUP_ENTRY_PAGE.CLICK_LAST,
    onNext: ADDRESS_GROUP_ENTRY_PAGE.CLICK_NEXT,
    onPrevious: ADDRESS_GROUP_ENTRY_PAGE.CLICK_PREVIOUS,
  })
)(SelectGroup);
