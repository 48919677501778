import {
  cloneDeep,
  get,
  isArray,
  isObject,
  isPlainObject,
  merge,
  omit,
  reduce,
  truncate,
} from "lodash";

import * as StringUtil from "@dpdgroupuk/mydpd-app/lib/utils/string";

import { Fields, FilterFields } from "~/constants/forms";
import { GB, IE } from "~/constants/strings";
import { ADDRESS_BOOK_TYPES } from "~/models/enum";
import { POSTCODE } from "~/models/validators/regex";
import { flatPathToObject } from "~/utils/object";
import {
  getQueryFilters,
  getQueryPagination,
  getQueryParam,
  stringifyQuery,
} from "~/utils/query";
import { mapErrorsToReduxForm } from "~/utils/reduxForm";

export const getImportErrors = payload => {
  const errors = [];
  const importData = get(payload, "data.import", []);
  importData.forEach(importItem => {
    const importErrors = get(importItem, "errors[0][0].errors", []);
    errors.push(...importErrors.map(error => error.errorMessage));
  });
  const error = get(payload, "error[0].message", "");

  if (!errors.length && error) {
    errors.push(error);
  }
  return errors;
};

export const getSearchCriteria = location => {
  let field = getQueryParam(location, FilterFields.SEARCH_CRITERIA_FIELD, "");
  let value = getQueryParam(location, FilterFields.SEARCH_CRITERIA_VALUE, "")
    .toString()
    .trim();
  if (value && !field) {
    field = Fields.SHORT_NAME;
  }

  if (field === Fields.POSTCODE && value.replace(/\s/g, "").match(POSTCODE)) {
    value = StringUtil.addSpaceToUKPostcode(value);
  }

  return field && value
    ? stringifyQuery({ [field]: value.toUpperCase() }, true)
    : null;
};

export const mapCountriesToKeyValue = countries =>
  countries.map(({ countryKey, countryName, euCountry }) => ({
    label: countryName,
    value: countryKey,
    euCountry,
  }));

export const trimEntityFields = entity =>
  reduce(
    entity,
    (acc, value, key) => {
      if (typeof value === "string") {
        acc[key] = value.trim();
      } else if (value && isPlainObject(value)) {
        acc[key] = trimEntityFields(value);
      } else {
        acc[key] = value;
      }

      return acc;
    },
    {}
  );

export const formatServerAddress = data => {
  const newRecord = cloneDeep(data);
  newRecord.addressBookType = newRecord.addressType;
  newRecord.isDefaultAddress = !!newRecord.isDefault;
  newRecord.isValid = newRecord.is_valid = true;
  newRecord.groups =
    newRecord.addressType === ADDRESS_BOOK_TYPES.DELIVERY
      ? newRecord.groups
      : [];

  if (newRecord.address.countryCode === GB) {
    newRecord.address.postcode = StringUtil.addSpaceToUKPostcode(
      newRecord.address.postcode
    );
  }

  return trimEntityFields(
    omit(newRecord, [
      "address.countryName",
      "auditInfo",
      "validationError",
      "isDefault",
      "addressType",
      "userId",
    ])
  );
};

export const isAddressbookReadonly = (addressBook, preferences, user) => {
  if (!addressBook) return false;

  if (
    get(addressBook, "readOnly") ||
    !!get(
      preferences,
      "advancedCustomerConfiguration.readOnlyAddressBook",
      false
    )
  ) {
    return true;
  }

  const ownerRecord = get(addressBook, "userId");
  const uid = get(user, "userId");

  return ownerRecord !== uid;
};

export const transformAddressValues = ({ street, locality, property }) => {
  let addressLine1;
  let addressLine2;

  if (property) {
    addressLine1 = `${property} ${street}`;
  } else {
    addressLine1 = street;
  }

  if (addressLine1 && addressLine1.length > 35) {
    addressLine1 = property;
    addressLine2 = `${street}, ${locality}`;
  } else {
    addressLine2 = locality;
  }

  if (addressLine2 && addressLine2.length > 35) {
    addressLine2 = truncate(addressLine2, {
      length: 35,
      separator: " ",
      omission: "",
    });
  }

  return {
    addressLine1,
    addressLine2,
  };
};

export const getInitialAddressState = (initialValues, queryStringValues) => {
  try {
    if (queryStringValues) {
      const source = JSON.parse(decodeURIComponent(queryStringValues));
      return merge(initialValues, source);
    }
  } catch (err) {
    // ignore error here
  }
  return initialValues;
};

export const getFormErrors = errors => {
  if (isArray(errors)) {
    return flatPathToObject(mapErrorsToReduxForm(errors));
  }

  return errors;
};

export const getSearchQuery = location => {
  const { searchCriteriaField, searchCriteriaValue, ...query } =
    getQueryFilters(location);
  const page = getQueryPagination(location);

  let queryRequest = {
    ...page,
    ...query,
    // Do not send type "0" to get all address book types
    type: parseInt(query.type) ? query.type : undefined,
  };

  const searchCriteria = getSearchCriteria(location);
  if (searchCriteria) {
    queryRequest = {
      ...queryRequest,
      searchCriteria,
    };
  }

  return queryRequest;
};

export const isCountryDomestic = countryCode =>
  countryCode === GB || countryCode === IE;

export const getCountryByCountryCode = (countryCode, countries) =>
  countries.find(({ value }) => value === countryCode);

export const getTouchedFields = fields =>
  reduce(
    fields,
    (acc, value, key) => {
      value && acc.push(key);
      return acc;
    },
    []
  );

export const flattenEntityRoutes = entity =>
  Object.values(entity).reduce((prev, curr) => {
    if (isObject(curr)) {
      return [...prev, ...flattenEntityRoutes(curr)];
    }
    return [...prev, curr];
  }, []);
