export const STATUS = {
  COMPLETE: "COMPLETE",
  PENDING: "PENDING",
  PROCESSING: "PROCESSING",
};

export const STATUS_TITLE = {
  COMPLETE: "Complete",
  PENDING: "Pending",
  PROCESSING: "Processing",
};
