import { isEmpty } from "lodash";

import { FIELDS_LENGTH } from "~/constants/forms";
import * as S from "~/constants/strings";

import * as validateRegex from "./regex";

export const addressBookValidator = (values, { countries, isDisabled }) => {
  const { value: countryCode, label: countryName } =
    (values.address &&
      countries?.find(
        ({ value, label }) =>
          value === values.address?.countryCode ||
          label.toUpperCase() === values.address?.countryCode
      )) ||
    {};
  const errors = {};

  if (isEmpty(values) || isDisabled) {
    return {};
  }

  if (!values.shortName?.trim()) {
    errors.shortName = S.SHORT_NAME_MUST_BE_ENTERED;
  }

  if (
    values.contactDetails &&
    values.contactDetails.telephone &&
    !values.contactDetails.telephone?.match(validateRegex.PHONE_NUMBER)
  ) {
    errors.contactDetails = {
      ...(errors.contactDetails || {}),
      telephone: S.TELEPHONE_MUST_BE_VALID,
    };
  }

  if (
    values.notificationDetails &&
    values.notificationDetails.email &&
    !values.notificationDetails.email?.match(validateRegex.EMAIL)
  ) {
    errors.notificationDetails = {
      ...(errors.notificationDetails || {}),
      email: S.NOTIFICATION_EMAIL_MUST_BE_VALID,
    };
  }

  if (
    values.notificationDetails &&
    values.notificationDetails.mobile &&
    !values.notificationDetails.mobile?.match(validateRegex.PHONE_NUMBER)
  ) {
    errors.notificationDetails = {
      ...(errors.notificationDetails || {}),
      mobile: S.NOTIFICATION_TEXT_MUST_BE_VALID,
    };
  }

  if (
    values.address &&
    values.address.countryCode === S.GB &&
    !values.address.postcode?.trim()
  ) {
    errors.address = {
      ...(errors.address || {}),
      postcode: S.POSTCODE_MUST_BE_ENTERED,
    };
  } else {
    if (
      values.address &&
      values.address.countryCode === S.GB &&
      !values.address.postcode?.match(validateRegex.POSTCODE)
    ) {
      errors.address = {
        ...(errors.address || {}),
        postcode: S.POSTCODE_MUST_BE_VALID,
      };
    }
  }

  if (
    values.address &&
    values.address.postcode?.trim() &&
    values.address.postcode.length > FIELDS_LENGTH.postcode
  ) {
    errors.address = {
      ...(errors.address || {}),
      postcode: S.$_MAXIMUM_OF_$(S.POSTCODE, FIELDS_LENGTH.postcode),
    };
  }

  if (!values.address) {
    errors.address = {
      ...(errors.address || {}),
      countryCode: S.DESTINATION_COUNTRY_MUST_BE_ENTERED,
      street: S.STREET_MUST_BE_ENTERED,
      town: S.TOWN_CITY_MUST_BE_ENTERED,
    };
  }

  if (values.address && !values.address.town?.trim()) {
    errors.address = {
      ...(errors.address || {}),
      town: S.TOWN_CITY_MUST_BE_ENTERED,
    };
  } else if (
    values.address &&
    values.address.town.length > FIELDS_LENGTH.default
  ) {
    errors.address = {
      ...(errors.address || {}),
      town: S.$_MAXIMUM_OF_$(S.CITY, FIELDS_LENGTH.default),
    };
  }

  if (values.address && !values.address.street?.trim()) {
    errors.address = {
      ...(errors.address || {}),
      street: S.STREET_MUST_BE_ENTERED,
    };
  } else if (
    values.address &&
    values.address.street.length > FIELDS_LENGTH.default
  ) {
    errors.address = {
      ...(errors.address || {}),
      street: S.$_MAXIMUM_OF_$(S.STREET, FIELDS_LENGTH.default),
    };
  }

  if (
    values.address &&
    values.address.locality?.trim() &&
    values.address.locality.length > FIELDS_LENGTH.default
  ) {
    errors.address = {
      ...(errors.address || {}),
      locality: S.$_MAXIMUM_OF_$(S.LOCALITY, FIELDS_LENGTH.default),
    };
  }

  if (
    values.address &&
    values.address.organisation?.trim() &&
    values.address.organisation.length > FIELDS_LENGTH.default
  ) {
    errors.address = {
      ...(errors.address || {}),
      organisation: S.$_MAXIMUM_OF_$(S.ORGANISATION, FIELDS_LENGTH.default),
    };
  }

  if (
    values.address &&
    values.address.county?.trim() &&
    values.address.county.length > FIELDS_LENGTH.default
  ) {
    errors.address = {
      ...(errors.address || {}),
      county: S.$_MAXIMUM_OF_$(S.COUNTY, FIELDS_LENGTH.default),
    };
  }

  if (
    values.address &&
    (!values.address.countryCode ||
      (values.address.countryCode !== countryCode &&
        values.address.countryCode !== countryName?.toUpperCase()))
  ) {
    let countryErrorMessage = S.INVALID_COUNTRY_ENTERED;

    if (!values.address.countryCode) {
      countryErrorMessage = S.DESTINATION_COUNTRY_MUST_BE_ENTERED;
    }

    errors.address = {
      ...(errors.address || {}),
      countryCode: countryErrorMessage,
    };
  }

  if (!values.addressType) {
    errors.addressType = S.ADDRESS_TYPE_MUST_BE_SELECTED;
  }

  // @see: https://it.dpduk.live/version/customer-shipping/ni-protocol-shipping/sprint-1/diag_AZdYie6C48kIGE9t.html
  // @see: https://it.dpduk.live/version/customer-shipping/ni-protocol-shipping/sprint-1/diag_kSV6bvGGAqCIPRlk.html
  if (
    values.ukimsNumber &&
    values.ukimsNumber?.trim() &&
    values.ukimsNumber.length !== FIELDS_LENGTH.ukimsNumber
  ) {
    errors.ukimsNumber = S.$_EQUAL_TO_$(
      S.UKIMS_NUMBER,
      FIELDS_LENGTH.ukimsNumber
    );
  }

  return errors;
};
