import get from "lodash/get";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { compose, lifecycle, withHandlers } from "recompose";

import { withAppUser, withNotifier } from "@dpdgroupuk/mydpd-app";
import {
  Card,
  Legend,
  Main,
  withLoader,
  withOverlay,
} from "@dpdgroupuk/mydpd-ui";
import { trackProps, withTrack } from "@dpdgroupuk/react-event-tracker";

import { ADDRESS_GROUP_ENTRY_PAGE } from "~/constants/analytics";
import {
  ADDRESS_BOOK_GROUPS,
  GROUP,
  GROUP_ADDRESSES,
  NETWORKS,
  PLEASE_COMPLETE_ALL_REQUIRED_FIELDS,
} from "~/constants/strings";
import withLoaderHandlers from "~/hocs/withLoaderHandlers";
import { ReferenceDataActions } from "~/redux/reference";
import { CREATE_GROUP, EDIT_GROUP } from "~/router";
import { getPathId, getQueryGroupAddressesPagination } from "~/utils/query";

import {
  fetchGroupAddressesById,
  fetchGroupById,
  fetchGroups,
} from "./actions";
import { CreateGroup, GroupAddresses, SelectGroup } from "./containers";
import styles from "./Groups.module.scss";

const Group = props => (
  <Main.Body>
    <Legend
      leftMessage={PLEASE_COMPLETE_ALL_REQUIRED_FIELDS}
      rightMessage={get(props.appUser, "username")}
    />
    <Card.Stack fluid>
      <Col md={5} className={styles.col}>
        <SelectGroup />
      </Col>
      <Col md={7} className={styles.col}>
        <Switch>
          <Route path={CREATE_GROUP} component={CreateGroup} />
          <Route path={EDIT_GROUP} component={GroupAddresses} />
        </Switch>
      </Col>
    </Card.Stack>
  </Main.Body>
);

Group.propTypes = {
  appUser: PropTypes.object,
};

export default compose(
  withAppUser,
  withNotifier,
  withOverlay,
  connect(null, (dispatch, { notifier }) => ({
    fetchAddressBookGroups: notifier.runAsync(() => dispatch(fetchGroups()), {
      entityName: ADDRESS_BOOK_GROUPS,
    }),
    fetchNetworks: notifier.runAsync(
      query => dispatch(ReferenceDataActions.fetchNetworks(query)),
      { entityName: NETWORKS }
    ),
    fetchGroup: notifier.runAsync(id => dispatch(fetchGroupById(id)), {
      entityName: GROUP,
    }),
    fetchGroupAddresses: notifier.runAsync(
      (id, query) => dispatch(fetchGroupAddressesById(id, query)),
      { entityName: GROUP_ADDRESSES }
    ),
  })),
  withLoaderHandlers,
  withHandlers({
    loadGroupById:
      ({ location, match, fetchGroup, fetchGroupAddresses }) =>
      () => {
        const groupId = getPathId(match, "params.groupId");

        if (groupId && groupId !== "create") {
          const {
            addressesSearchPage: searchPage,
            addressesSearchPageSize: searchPageSize,
          } = getQueryGroupAddressesPagination(location);
          return Promise.all([
            fetchGroup(groupId),
            fetchGroupAddresses(groupId, { searchPage, searchPageSize }),
          ]);
        } else {
          return Promise.resolve();
        }
      },
  }),
  withLoader({
    loadFn: ({
      fetchAddressBookGroups,
      fetchNetworks,
      loadGroupById,
      appUser,
    }) =>
      Promise.all([
        fetchNetworks({ businessUnit: appUser.businessId }),
        fetchAddressBookGroups(),
        loadGroupById(),
      ]),
  }),
  withTrack(trackProps(ADDRESS_GROUP_ENTRY_PAGE)),
  lifecycle({
    async componentDidUpdate(prevProps) {
      if (prevProps.location.pathname !== this.props.location.pathname) {
        this.props.overlay.show();
        await this.props.loadGroupById();
        this.props.overlay.hide();
      }
    },
  })
)(Group);
