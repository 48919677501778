import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { apisClient } from "~/apis";

export const ActionTypes = createActionTypes("COLLECTIONS", {
  SEARCH: createAsyncActionTypes("SEARCH"),
  CLEAR: "CLEAR",
});

export const searchCollections = createAsyncAction(
  (query, _, fetchOptions) =>
    apisClient.reference
      .searchCollections(query, fetchOptions)
      .then(({ data }) => data),
  ActionTypes.SEARCH
);
