import { AddressBookActions } from "~/redux/orm";

export const searchAddressBooks = AddressBookActions.searchAddressBooks;

export const deleteAddressBookById = AddressBookActions.deleteAddressBookById;

export const deleteAddressBook = AddressBookActions.deleteAllAddressBooks;

export const fetchAddressBookById = AddressBookActions.fetchAddressBookById;

export const updateAddressBook = AddressBookActions.updateAddressBook;

export const createAddressBook = AddressBookActions.createAddressBook;
