import _ from "lodash";

export const omitNilValues = obj => _.pickBy(obj, _.identity);

export const flatPathToObject = obj => {
  const result = {};

  _.forEach(obj, (value, key) => {
    _.set(result, key, value);
  });

  return result;
};

export const replaceEmptyStringToUndefined = obj =>
  _.mapValues(obj, value => {
    if (_.isString(value) && value.trim() === "") {
      value = undefined;
    } else if (value === null) {
      value = undefined;
    } else if (_.isPlainObject(value)) {
      value = replaceEmptyStringToUndefined(value);
    }

    return value;
  });

export const toUppercaseValues = (obj, omit = ["userId", "addressBookId"]) =>
  _.mapValues(obj, (value, key) => {
    if (_.isString(value) && !omit.includes(key)) {
      value = value.toUpperCase();
    } else if (_.isPlainObject(value)) {
      value = toUppercaseValues(value);
    }

    return value;
  });

export const getDeepKeys = obj => {
  let keys = [];

  for (const key in obj) {
    if (_.isObject(obj[key]) && !_.isEmpty(obj[key])) {
      const subKeys = getDeepKeys(obj[key]);
      keys = keys.concat(
        subKeys.map(function (subkey) {
          return key + "." + subkey;
        })
      );
    } else {
      keys.push(key);
    }
  }
  return keys;
};

export const getValue = (object, path, defaultValue) => {
  const value = _.get(object, path);
  if (value === undefined || value === null) {
    return defaultValue;
  } else {
    return value;
  }
};

export const setUndefinedToEmptyRequiredFields = (values, requiredKeys) => {
  const result = _.cloneDeep(values);
  _.forEach(requiredKeys, path => {
    _.set(result, path, getValue(values, path, ""));
  });
  return replaceEmptyStringToUndefined(result);
};

export const getAllObjectKeysWhereValueIsTrue = object =>
  Object.keys(object).filter(key => object[key]) ?? [];
