import { attr, Model } from "redux-orm";

import ENTITY_NAME from "../entities";
import ActionTypes from "./actionTypes";

export class Group extends Model {
  static reducer(action, GroupModel) {
    switch (action.type) {
      case ActionTypes.FETCH.SUCCESS:
        action.payload.data.results.forEach(group => GroupModel.upsert(group));
        break;
      case ActionTypes.FETCH_ADDRESSES.SUCCESS:
        GroupModel.withId(action.payload.groupId).set(
          "addresses",
          action.payload.data
        );
        break;
      case ActionTypes.GET.SUCCESS:
        GroupModel.upsert(action.payload);
        break;
      case ActionTypes.UPDATE.SUCCESS:
        GroupModel.withId(action.payload.groupId).update(action.payload.body);
        break;
      case ActionTypes.DELETE.SUCCESS:
        GroupModel.withId(action.payload.id).delete();
        break;
      case ActionTypes.CREATE.SUCCESS:
        GroupModel.create(action.payload);
        break;
    }
  }

  static modelName = ENTITY_NAME.ADDRESS_BOOK_GROUP;

  static fields = {
    groupId: attr(),
  };

  static options = {
    idAttribute: "groupId",
  };
}
