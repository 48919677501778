import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./Card.module.scss";

const Card = ({
  className,
  children,
  label,
  disabled,
  required,
  helperText,
  active,
  ...rest
}) => (
  <div
    {...rest}
    className={classNames(
      styles.fieldContainer,
      styles.defaultColors,
      active && styles.activeColors,
      disabled && styles.disabledColors,
      className && className
    )}
  >
    <div className={classNames(styles.field)}>
      {label && !!children && !!children.length && (
        <span className={classNames(styles.label)}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </span>
      )}
      <div className={classNames(styles.body)} placeholder={label}>
        {label && !!children && !children.length && (
          <span className={classNames(styles.placeholder)}>{label}</span>
        )}
        {children}
      </div>
      {helperText && (
        <span className={classNames(styles.helperText)}>{helperText}</span>
      )}
    </div>
  </div>
);

Card.defaultProps = {
  type: "text",
  multiline: false,
  required: false,
};

Card.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  classNameField: PropTypes.string,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.any,
  multiline: PropTypes.any,
};

export default Card;
