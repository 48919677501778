import { createSelector } from "reselect";

import { GB, IE } from "~/constants/strings";
import { excludeUnsupportedNetworks } from "~/models/references";

import { AuthSelectors } from "../auth";

export const getData = state => state.app.referenceData;

export const getCountries = createSelector(getData, ({ countries }) =>
  countries.sort((a, b) => {
    if (a.countryKey === GB) return -1;
    if (b.countryKey === GB) return 1;
    if (a.countryKey === IE) return -1;
    if (b.countryKey === IE) return 1;

    return a.countryName > b.countryName ? 1 : -1;
  })
);

export const getSupportedNetworks = createSelector(
  getData,
  AuthSelectors.getAuthUser,
  ({ networks }, { serviceExclusions }) =>
    excludeUnsupportedNetworks(networks, serviceExclusions)
);

export const getArrayOfSupportedNetworks = createSelector(
  getData,
  AuthSelectors.getAuthUser,
  ({ networks }, { serviceRestricted }) =>
    excludeUnsupportedNetworks(networks, serviceRestricted).map(
      ({ networkCode }) => networkCode
    )
);

export const getNetworksKeyValue = createSelector(
  getSupportedNetworks,
  networks =>
    networks.map(({ networkCode: value, networkDescription: label }) => ({
      label,
      value,
    }))
);
