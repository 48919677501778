import { sortBy } from "lodash";

export const transformImportResult = imported =>
  sortBy(
    imported
      ?.map(({ shortName, line, warnings, errors }) => {
        if (warnings?.length > 0 && errors?.length > 0) {
          return [
            { errors, line, shortName },
            { warnings, line, shortName },
          ];
        } else if (errors?.length > 0) {
          return { errors, line, shortName };
        } else if (warnings?.length > 0) {
          return { warnings, line, shortName };
        }

        return null;
      })
      .flat(2),
    ["line"]
  );
