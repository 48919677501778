export const upperCaseNormalize = v => v && v.toUpperCase();

export const phoneNumberNormalize = value => {
  if (!value) {
    return value;
  }

  let phoneValue = value?.replace(/[^+\d]/g, "");
  const plusCharacterIndex = phoneValue?.lastIndexOf("+");

  if (plusCharacterIndex !== -1 && plusCharacterIndex !== 0) {
    phoneValue =
      phoneValue?.slice(0, plusCharacterIndex) +
      phoneValue?.slice(plusCharacterIndex + 1);
  }

  return phoneValue.substring(0, 15);
};

const TRUTHY = ["true", "y", 1, "1", "a"];
const FALSY = ["false", "n", 0, "0", "b"];

export const convertToBool = (value, forceCust = false) => {
  const originValue = value;
  if (typeof value !== "boolean") {
    if (typeof value === "string") {
      value = value.toLowerCase();
    }
    return TRUTHY.includes(value)
      ? true
      : FALSY.includes(value)
      ? false
      : forceCust
      ? null
      : originValue;
  }
  return forceCust ? null : originValue;
};
