import { ADDRESS_BOOK_FORM, UPDATE_AND_SAVE_HELP } from "~/constants/forms";

export default {
  [ADDRESS_BOOK_FORM]: [
    {
      name: "Short Name",
      content: (
        <>
          <p>
            A unique short name that is used to search for, or identify, the
            return address.
          </p>
        </>
      ),
    },
    {
      name: "Address Type",
      content: "Is the address a delivery address, or a return address?",
    },
    {
      name: "Destination Country",
      content: "The country of the delivery address.",
    },
    {
      name: "Notification Email",
      content:
        "Enter the recipients email address to recieve tracking notifications including their 1 hour delivery timeslot.",
    },
    {
      name: "Postal / Zip code",
      content:
        "Enter the Postal/Zip Code here. For UK shipments only, a dropdown list will appear enabling you to select the delivery address",
    },
    {
      name: "Mobile notification text",
      content:
        "Enter the recipients mobile number to recieve SMS tracking notifications including their 1 hour delivery timeslot.",
    },
    {
      name: "Organisation / Name",
      content: "The business name, or recipient of the shipment.",
    },
    {
      name: "Address Line 1",
      content:
        "The address you are sending the shipment to. Address line 1, City, and the Postal/Zip Code are mandatory(except ROI) Maximum 35 characters per address line.",
    },
    {
      name: "Address Line 2",
      content:
        "The address you are sending the shipment to. Address line 1, City, and the Postal/Zip Code are mandatory(except ROI) Maximum 35 characters per address line.",
    },
    {
      name: "City",
      content:
        "The address you are sending the shipment to. Address line 1, City, and the Postal/Zip Code are mandatory(except ROI) Maximum 35 characters per address line.",
    },
    {
      name: "Country/State",
      content:
        "The address you are sending the shipment to. Address line 1, City, and the Postal/Zip Code are mandatory(except ROI) Maximum 35 characters per address line.",
    },
    {
      name: "Reference 1",
      content: (
        <>
          <p>
            Reference 1 is usually an order, or picking number provided by the
            shipper. E.g. ORD1234567.
            <br />
            Reference 2 and 3 are extra fields that can be used for any other
            reference details. All reference fields will appear on the shipping
            label. Maximum 25 characters for each field.
          </p>
        </>
      ),
    },
    {
      name: "Reference 2",
      content: (
        <>
          <p>
            Reference 1 is usually an order, or picking number provided by the
            shipper. E.g. ORD1234567.
            <br />
            Reference 2 and 3 are extra fields that can be used for any other
            reference details. All reference fields will appear on the shipping
            label. Maximum 25 characters for each field.
          </p>
        </>
      ),
    },
    {
      name: "Reference 3",
      content: (
        <>
          <p>
            Reference 1 is usually an order, or picking number provided by the
            shipper. E.g. ORD1234567.
            <br />
            Reference 2 and 3 are extra fields that can be used for any other
            reference details. All reference fields will appear on the shipping
            label. Maximum 25 characters for each field.
          </p>
        </>
      ),
    },
    {
      name: "Contact name",
      content: "The name of a person we can contact regarding the delivery.",
    },
    {
      name: "EORI number",
      content: (
        <>
          <p>
            For EU shipments going to a business (B2B), the business&apos; EU
            EORI number must be entered here.
          </p>
          <p>
            The foemat of an EU EORI is the country code of the issuing Member
            State, followed be a code or number that is unique in the Member
            State.
          </p>
          <p>
            EORI formats differ across Europe, so please check this is valid
            before sending your parcel to avoid delays or your parcel being
            returned.
          </p>
          <p>
            This information is required for Customs clearance of B2B parcels.
          </p>
        </>
      ),
    },
    {
      name: "Contact Telephone",
      content:
        "The telephone number of a person we can contact regarding the delivery.",
    },
    {
      name: "VAT Number",
      content: "VAT number of the recipient",
    },
    {
      name: "PID Number",
      content: (
        <>
          <p>
            Some destinations require a Personal identification Number for the
            receiver of the goods of a B2C shipment in order for Customs to
            clear the shipment.
          </p>
          <p>
            Requirements and formats vary by destination, however examples
            include a DNI or NIE for Spain, Norwegian National Identity Number
            for Norway, an Italian Codice Fiscale or a KYC number for India.
          </p>
          <p>
            Please check if this is required before sending your parcel to avoid
            delays or your parcel being returned.
          </p>
        </>
      ),
    },
  ],
  [UPDATE_AND_SAVE_HELP]: [
    {
      name: "Update & Save",
      content: (
        <>
          <p>
            Once the Address book has been populated with addresses, they can be
            edited and updated within the <b>Address Book</b> tab.
          </p>
          <p>
            Select the Address record from the list on the left and the complete
            record will be shown on the right side of the application.
          </p>
          <p>
            Once the required changes have been made, click on{" "}
            <b>Save Changes</b> to complete the process.
          </p>
        </>
      ),
    },
  ],
};
