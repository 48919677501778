import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";

import { createCancelableRequest } from "@dpdgroupuk/fetch-client";
import PostcodeFinder from "@dpdgroupuk/mydpd-postcode-finder";
import { withSnackbar } from "@dpdgroupuk/mydpd-ui";
import {
  trackProps,
  withTrack,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import { FIND_POSTCODE_MODAL } from "~/constants/analytics";
import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import { GB, POSTCODES } from "~/constants/strings";
import { getCountryCode } from "~/pages/AddressBook/selectors";
import { getErrorMessage, isIgnoredError } from "~/utils/error";
import { omitNilValues } from "~/utils/object";

import { search } from "./actions";

export default compose(
  withSnackbar,
  connect(
    state => ({
      countryCode: getCountryCode(state),
    }),
    (dispatch, { snackbar }) => ({
      search: createCancelableRequest(async (values, options) => {
        try {
          return await dispatch(search(omitNilValues(values), options));
        } catch (err) {
          if (!isIgnoredError(err)) {
            snackbar.showAlert({
              message: getErrorMessage(err, POSTCODES),
              displayTime: SHOW_ALERT_DISPLAY_TIME,
            });
          }
        }
      }),
    })
  ),
  withHandlers({
    onSelectAddress: props => values => props.onSelectionChange(values),
    shouldSearch: props => () => props.countryCode === GB && !props.meta.error,
  }),
  withTrack(trackProps(FIND_POSTCODE_MODAL)),
  withTrackProps({
    onFind: FIND_POSTCODE_MODAL.CLICK_FIND_POSTCODE,
    onClear: FIND_POSTCODE_MODAL.CLICK_CLEAR,
    onShow: FIND_POSTCODE_MODAL.LOAD,
    onSelectAddress: FIND_POSTCODE_MODAL.CLICK_ADDRESS,
  })
)(PostcodeFinder);
