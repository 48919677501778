import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { compose } from "redux";
import { Field } from "redux-form";

import { Form } from "@dpdgroupuk/mydpd-ui";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { ADDRESS_GROUP_ENTRY_PAGE } from "~/constants/analytics";
import { Fields, FIELDS_LENGTH } from "~/constants/forms";
import { AVAILABLE_SERVICES, GROUP_NAME, UPPERCASE } from "~/constants/strings";
import { upperCaseNormalize } from "~/models/normalizers";

const GroupForm = props => (
  <Container fluid className="p-0">
    <Row>
      <Col md={5}>
        <Field
          component={Form.MaterialTextInput}
          label={GROUP_NAME}
          name={Fields.GROUP_NAME}
          onChange={props.onChange}
          disabled={props.disabled}
          maxLength={FIELDS_LENGTH.default}
          helperText
          onBlur={props.onFieldEntry}
          normalize={upperCaseNormalize}
        />
      </Col>
      <Col md={7}>
        <Field
          component={Form.MaterialDropdown}
          label={AVAILABLE_SERVICES}
          helperText
          name={Fields.NETWORK_CODE}
          values={props.networks}
          onChange={props.onSelect}
          disabled={props.disabled}
          onBlur={props.onFieldEntry}
          textTransform={UPPERCASE}
        />
      </Col>
    </Row>
  </Container>
);

GroupForm.propTypes = {
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onFieldEntry: PropTypes.func,
  networks: PropTypes.array,
  disabled: PropTypes.bool,
};

export default compose(
  withTrackProps({
    onFieldEntry: name => {
      switch (name) {
        case Fields.GROUP_NAME:
          return ADDRESS_GROUP_ENTRY_PAGE.ENTER_TEXT_GROUP_NAME;
        case Fields.NETWORK_CODE:
          return ADDRESS_GROUP_ENTRY_PAGE.SELECT_AVAILABLE_SERVICES;
        default:
          break;
      }
    },
  })
)(GroupForm);
