import PropTypes from "prop-types";

import styles from "./CardStepFooter.module.scss";

const CardStepFooter = ({ children }) => (
  <div className={styles.footer}>{children}</div>
);

CardStepFooter.propTypes = {
  children: PropTypes.node,
};

export default CardStepFooter;
