import { useMemo } from "react";

import get from "lodash/get";
import PropTypes from "prop-types";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";
import { useColumnOrder } from "react-table/src/plugin-hooks/useColumnOrder";
import { useFlexLayout } from "react-table/src/plugin-hooks/useFlexLayout";
import { useResizeColumns } from "react-table/src/plugin-hooks/useResizeColumns";
import { compose, lifecycle, withHandlers } from "recompose";

import {
  useUserHeaderStatePersist,
  withAppUser,
  withNotifier,
} from "@dpdgroupuk/mydpd-app";
import {
  Card,
  DndTable,
  Legend,
  Main,
  withOverlay,
} from "@dpdgroupuk/mydpd-ui";

import * as S from "~/constants/strings";
import { ExportAddressBookSelectors } from "~/redux/orm";
import { EXPORTS } from "~/router";
import { defaultFormatDateTime } from "~/utils/date";
import {
  getQueryFilters,
  getQueryPagination,
  stringifyQuery,
} from "~/utils/query";

import styles from "./ExportAddressBook.module.scss";
import {
  getDownloadFileUrl,
  refreshAddressBook,
  searchExportAddressBooks,
} from "./redux/actions";
import {
  ExportAddressBookEntity,
  getActionExportAddressBookColumns,
} from "./redux/models";
import { getExportAddressBooksTotalResults } from "./redux/selectors";

const DEFAULT_EXPORT_COLUMNS = [
  ExportAddressBookEntity.USER_ID,
  ExportAddressBookEntity.FILE_NAME,
  ExportAddressBookEntity.CREATED_AT,
  ExportAddressBookEntity.LAST_UPDATED,
  ExportAddressBookEntity.STATUS,
];

const ExportAddressBook = props => {
  const { searchPage, searchPageSize } = useMemo(
    () => getQueryPagination(props.location),
    [props.location]
  );
  const data = useMemo(
    () =>
      props.exportAddressBook.map(item => ({
        ...item,
        createdAt: item.createdAt && defaultFormatDateTime(item.createdAt),
        lastUpdated:
          item.lastUpdated && defaultFormatDateTime(item.lastUpdated),
      })),
    [props.exportAddressBook]
  );
  const defaultColumn = useMemo(
    () => ({
      minWidth: 80,
      width: 200,
      maxWidth: 500,
    }),
    []
  );
  const tableColumns = useMemo(
    () =>
      getActionExportAddressBookColumns({
        onDownloadFile: props.onDownloadFile,
        onClickRefresh: props.onClickRefresh,
      }),
    [props.onDownloadFile, props.onClickRefresh]
  );

  return (
    <Main.Body>
      <Legend rightMessage={get(props.appUser, "username")} />
      <Card.Stack fluid>
        <Col className="p-0">
          <Card>
            <Card.Header className="mb-4 d-flex justify-content-between">
              {S.EXPORTS}
            </Card.Header>
            <Row>
              <Col sm={12}>
                <DndTable
                  data={data}
                  columns={tableColumns}
                  classes={{
                    container: styles.tableContainer,
                    table: styles.table,
                    popover: styles.popover,
                  }}
                  tableHooks={[
                    useFlexLayout,
                    useResizeColumns,
                    useColumnOrder,
                    useUserHeaderStatePersist,
                  ]}
                  initialState={{
                    columnOrder: DEFAULT_EXPORT_COLUMNS,
                    storageKey: "exportAddressBookColumns",
                  }}
                  isVisiblePopover
                  defaultColumn={defaultColumn}
                  onClickRow={props.onClickRow}
                >
                  <DndTable.DndPaginator
                    page={parseInt(searchPage)}
                    totalCount={props.totalResults}
                    pageSize={parseInt(searchPageSize)}
                    onPrevious={props.onPrevious}
                    onNext={props.onNext}
                    onFirst={props.onFirst}
                    onLast={props.onLast}
                  />
                </DndTable>
              </Col>
            </Row>
          </Card>
        </Col>
      </Card.Stack>
    </Main.Body>
  );
};

ExportAddressBook.propTypes = {
  authUser: PropTypes.object,
  onLast: PropTypes.func,
  onFirst: PropTypes.func,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onDownloadFile: PropTypes.func,
  onClickRefresh: PropTypes.func,
  totalResults: PropTypes.number,
  onClickRow: PropTypes.func,
  appUser: PropTypes.object,
  exportAddressBook: PropTypes.array,
  location: PropTypes.object,
};

export default compose(
  withAppUser,
  withNotifier,
  withOverlay,
  connect(
    state => ({
      exportAddressBook:
        ExportAddressBookSelectors.getExportAddressBooks(state),
      totalResults: getExportAddressBooksTotalResults(state),
    }),
    (dispatch, { history, location, notifier, overlay }) => {
      const getModifiedQueryParams = newQuery => {
        const page = getQueryPagination(location);
        const query = getQueryFilters(location);

        return stringifyQuery({
          ...page,
          ...query,
          ...newQuery,
        });
      };
      const searchExportAddressBook = notifier.runAsync(
        async params => {
          const query = params || getQueryPagination(location);

          try {
            overlay.show();
            return await dispatch(searchExportAddressBooks(query));
          } finally {
            overlay.hide();
          }
        },
        { entityName: "exportAddressBook" }
      );
      const changeFilter = newQuery => {
        history.push({
          pathname: EXPORTS,
          search: getModifiedQueryParams(newQuery),
        });
      };
      const onNextOrPrev = async searchPage => {
        changeFilter({ searchPage });
        const { searchPageSize } = getQueryPagination(location);

        await searchExportAddressBook({
          searchPageSize,
          searchPage,
        });
      };
      const generateFileUrl = params =>
        dispatch(
          getDownloadFileUrl({
            exportAddressBookId: params.exportAddressBookId,
          })
        );

      return {
        dispatch,
        generateFileUrl,
        onNext: onNextOrPrev,
        onPrevious: onNextOrPrev,
        onFirst: onNextOrPrev,
        onLast: onNextOrPrev,
        searchExportAddressBook,
      };
    }
  ),
  withHandlers({
    onClickRefresh: ({ notifier, overlay, dispatch }) =>
      notifier.runAsync(
        async params => {
          try {
            overlay.show();
            await dispatch(
              refreshAddressBook({
                exportAddressBookId: params.exportAddressBookId,
              })
            );
          } finally {
            overlay.hide();
          }
        },
        { entityName: "exportAddressBook" }
      ),
    onDownloadFile: ({ notifier, overlay, generateFileUrl }) =>
      notifier.runAsync(
        async params => {
          try {
            overlay.show();
            const { fileUrl } = await generateFileUrl(params);
            const alink = document.createElement("a");

            alink.href = fileUrl;
            alink.click();
          } finally {
            overlay.hide();
          }
        },
        { entityName: "exportAddressBook" }
      ),
  }),
  lifecycle({
    async componentDidMount() {
      this.props.searchExportAddressBook();
    },
  })
)(ExportAddressBook);
