import PropTypes from "prop-types";

const Download = ({ classNameSvg, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNameSvg}
    {...props}
  >
    <path
      d="M12 15.5769L8.46152 12.0384L9.16923 11.3192L11.5 13.65V5H12.5V13.65L14.8308 11.3192L15.5385 12.0384L12 15.5769ZM6.61537 19C6.15512 19 5.77083 18.8458 5.4625 18.5375C5.15417 18.2292 5 17.8449 5 17.3846V14.9616H6V17.3846C6 17.5385 6.0641 17.6795 6.1923 17.8077C6.32052 17.9359 6.46154 18 6.61537 18H17.3846C17.5385 18 17.6795 17.9359 17.8077 17.8077C17.9359 17.6795 18 17.5385 18 17.3846V14.9616H19V17.3846C19 17.8449 18.8458 18.2292 18.5375 18.5375C18.2292 18.8458 17.8449 19 17.3846 19H6.61537Z"
      fill="#1C1B1F"
    />
  </svg>
);

Download.propTypes = {
  classNameSvg: PropTypes.string,
};

export default Download;
