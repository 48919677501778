import { ExportAddressBookActionTypes } from "~/redux/orm";

export const initialState = {
  totalResults: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ExportAddressBookActionTypes.SEARCH.SUCCESS: {
      return {
        ...state,
        totalResults: action.payload.totalResults,
      };
    }

    case ExportAddressBookActionTypes.GENERATE_FILE_URL.SUCCESS: {
      return {
        ...state,
        exportFile: {
          fileUrl: action.payload.fileUrl,
          exportAddressBookId: action.payload.exportAddressBookId,
        },
      };
    }

    default:
      return state;
  }
};
