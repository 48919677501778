import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import { compose, lifecycle } from "recompose";
import { propTypes } from "redux-form";

import { Button } from "@dpdgroupuk/mydpd-ui";

import { ADDRESS_BOOK_ENTRY_PAGE } from "~/constants/analytics";
import { AddressBookEntity } from "~/constants/forms";
import * as S from "~/constants/strings";
import BaseBook from "~/features/BaseBook/BaseBook";
import { ADDRESS_BOOK_TYPES } from "~/models/enum";
import { ADDRESSES } from "~/router";

import UpdatesAndSave from "../../../../components/UpdatesAndSave";
import AddressCard from "../../components/AddressCard";
import ValidationAndAssociatedGroups from "../../components/ValidationAndAssociatedGroups";
import * as model from "../../models";
import styles from "./EditAddress.module.scss";

const EditAddressBook = ({ onDeleteClick, onSubmit, ...otherProps }) => (
  <>
    <BaseBook title={S.SEARCH_AND_SELECT_ADDRESS} {...otherProps}>
      <Col md={6} className={styles.column}>
        <AddressCard {...otherProps}>
          <div className={classNames(styles.footer)}>
            <Button
              disabled={otherProps.isReadonly || !otherProps.initialized}
              variant="danger"
              onClick={onDeleteClick.bind({}, otherProps)}
            >
              {S.DELETE_RECORD}
            </Button>
          </div>
        </AddressCard>
      </Col>
      <Col className="col-12">
        <ValidationAndAssociatedGroups {...otherProps} />
      </Col>
    </BaseBook>

    <UpdatesAndSave
      {...otherProps}
      onSubmit={onSubmit}
      analyticsMetaDate={ADDRESS_BOOK_ENTRY_PAGE}
      dirty={otherProps.dirty || otherProps.hasInitialUrlValues}
    />
  </>
);

EditAddressBook.propTypes = {
  searchAddressBooks: PropTypes.func,
  addressBookGroups: PropTypes.arrayOf(PropTypes.object),
  ...propTypes,
};

export default compose(
  lifecycle({
    async componentDidMount() {
      const { touch } = this.props;
      await Promise.any([
        this.props.fetchAddressBookById(
          this.props.match.params.id,
          ADDRESS_BOOK_TYPES.RETURN
        ),
        this.props.fetchAddressBookById(
          this.props.match.params.id,
          ADDRESS_BOOK_TYPES.DELIVERY
        ),
      ]).catch(() => {
        this.props.snackbar.showAlert({ message: S.ADDRESSBOOK_NOT_FOUND });
        this.props.history.replace(ADDRESSES);
      });
      touch(...model.flattenEntityRoutes(AddressBookEntity));
    },
    componentDidUpdate(prevProps) {
      const { touch, addressBookId } = this.props;
      if (addressBookId !== prevProps.addressBookId) {
        touch(...model.flattenEntityRoutes(AddressBookEntity));
      }
    },
  })
)(EditAddressBook);
