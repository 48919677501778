import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { ADDRESS_BOOK_ENTRY_PAGE } from "~/constants/analytics";
import { AddressBookEntity } from "~/constants/forms";

const MAP_FIELD_TO_ACTION_ID = {
  [AddressBookEntity.SHORT_NAME]: ADDRESS_BOOK_ENTRY_PAGE.ON_ENTER_SHORT_NAME,
  [AddressBookEntity.ADDRESS.COUNTRY_CODE]:
    ADDRESS_BOOK_ENTRY_PAGE.ON_SELECT_COUNTRY,
  [AddressBookEntity.ADDRESS.POSTCODE]:
    ADDRESS_BOOK_ENTRY_PAGE.ON_ENTER_POSTCODE,
  [AddressBookEntity.ADDRESS.ORGANISATION]:
    ADDRESS_BOOK_ENTRY_PAGE.ON_ENTER_ORGANISATION,
  [AddressBookEntity.ADDRESS.STREET]:
    ADDRESS_BOOK_ENTRY_PAGE.ON_ENTER_ADDRESS_LINE_1,
  [AddressBookEntity.ADDRESS.LOCALITY]:
    ADDRESS_BOOK_ENTRY_PAGE.ON_ENTER_ADDRESS_LINE_2,
  [AddressBookEntity.ADDRESS.TOWN]: ADDRESS_BOOK_ENTRY_PAGE.ON_ENTER_TOWN,
  [AddressBookEntity.ADDRESS.COUNTY]: ADDRESS_BOOK_ENTRY_PAGE.ON_ENTER_COUNTY,
  [AddressBookEntity.CONTACT_DETAILS.CONTACT_NAME]:
    ADDRESS_BOOK_ENTRY_PAGE.ON_ENTER_CONTACT,
  [AddressBookEntity.CONTACT_DETAILS.TELEPHONE]:
    ADDRESS_BOOK_ENTRY_PAGE.ON_ENTER_TELEPHONE,
  [AddressBookEntity.DELIVERY_INSTRUCTION]:
    ADDRESS_BOOK_ENTRY_PAGE.ON_ENTER_DELIVERY_INSTRUCTION,
  [AddressBookEntity.IS_DEFAULT]: ADDRESS_BOOK_ENTRY_PAGE.ON_SELECT_IS_DEFAULT,
  [AddressBookEntity.NOTIFICATION_DETAILS.MOBILE]:
    ADDRESS_BOOK_ENTRY_PAGE.ON_ENTER_NOTIFICATION_MOBILE,
  [AddressBookEntity.NOTIFICATION_DETAILS.EMAIL]:
    ADDRESS_BOOK_ENTRY_PAGE.ON_ENTER_NOTIFICATION_EMAIL,
  [AddressBookEntity.SHIPPING_REF_1]:
    ADDRESS_BOOK_ENTRY_PAGE.ON_ENTER_SHIPPING_REF_1,
  [AddressBookEntity.SHIPPING_REF_2]:
    ADDRESS_BOOK_ENTRY_PAGE.ON_ENTER_SHIPPING_REF_2,
  [AddressBookEntity.SHIPPING_REF_3]:
    ADDRESS_BOOK_ENTRY_PAGE.ON_ENTER_SHIPPING_REF_3,
  [AddressBookEntity.VAT_NUMBER]: ADDRESS_BOOK_ENTRY_PAGE.ON_ENTER_VAT_NUMBER,
  [AddressBookEntity.ADDRESS_TYPE]:
    ADDRESS_BOOK_ENTRY_PAGE.ON_SELECT_ADDRESS_TYPE,
};

export default withTrackProps({
  onDeleteClick: ADDRESS_BOOK_ENTRY_PAGE.ON_CLICK_DELETE,
  onFieldEntry: (event, newValue, previousValue, name) =>
    MAP_FIELD_TO_ACTION_ID[name],
});
