import { AddressBookEntity } from "./forms";
import {
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  CONTACT,
  COUNTRY,
  COUNTY,
  DELIVERY_INSTRUCTIONS,
  EMAIL,
  MOBILE,
  ORGANISATION,
  POSTCODE,
  REF_1,
  REF_2,
  REF_3,
  SHORT_NAME,
  TELEPHONE,
  TOWN,
  VALID,
  VAT_NUMBER,
} from "./strings";

export const ADDRESS_COLUMNS = [
  {
    Header: SHORT_NAME,
    accessor: AddressBookEntity.SHORT_NAME,
    width: 98,
  },
  {
    Header: COUNTRY,
    accessor: AddressBookEntity.ADDRESS.COUNTRY_CODE,
    width: 80,
  },
  {
    Header: POSTCODE,
    accessor: AddressBookEntity.ADDRESS.POSTCODE,
    width: 85,
  },
  {
    Header: ORGANISATION,
    accessor: AddressBookEntity.ADDRESS.ORGANISATION,
    width: 110,
  },
  {
    Header: ADDRESS_LINE_1,
    accessor: AddressBookEntity.ADDRESS.STREET,
    width: 120,
  },
  {
    Header: ADDRESS_LINE_2,
    accessor: AddressBookEntity.ADDRESS.LOCALITY,
    width: 120,
  },
  {
    Header: TOWN,
    accessor: AddressBookEntity.ADDRESS.TOWN,
    width: 80,
  },
  {
    Header: COUNTY,
    accessor: AddressBookEntity.ADDRESS.COUNTY,
    width: 80,
  },
  {
    Header: CONTACT,
    accessor: AddressBookEntity.CONTACT_DETAILS.CONTACT_NAME,
    width: 80,
  },
  {
    Header: TELEPHONE,
    accessor: AddressBookEntity.CONTACT_DETAILS.TELEPHONE,
    width: 90,
  },
  {
    Header: EMAIL,
    accessor: AddressBookEntity.NOTIFICATION_DETAILS.EMAIL,
    width: 80,
  },
  {
    Header: MOBILE,
    accessor: AddressBookEntity.NOTIFICATION_DETAILS.MOBILE,
    width: 80,
  },
  {
    Header: DELIVERY_INSTRUCTIONS,
    accessor: AddressBookEntity.DELIVERY_INSTRUCTION,
    width: 180,
  },
  {
    Header: REF_1,
    accessor: AddressBookEntity.SHIPPING_REF_1,
    width: 80,
  },
  {
    Header: REF_2,
    accessor: AddressBookEntity.SHIPPING_REF_2,
    width: 80,
  },
  {
    Header: REF_3,
    accessor: AddressBookEntity.SHIPPING_REF_3,
    width: 80,
  },
  {
    Header: VAT_NUMBER,
    accessor: AddressBookEntity.VAT_NUMBER,
    width: 100,
  },
  {
    Header: VALID,
    accessor: AddressBookEntity.IS_VALID,
    Cell: ({
      row: {
        original: { isValid },
      },
    }) => (isValid ? "Yes" : "No"),
    width: 70,
  },
];

export const DEFAULT_ADDRESS_COLUMN_ORDER = [
  AddressBookEntity.SHORT_NAME,
  AddressBookEntity.ADDRESS.POSTCODE,
  AddressBookEntity.ADDRESS.STREET,
  AddressBookEntity.IS_VALID,
];
