import { AddressBookImportFields } from "~/constants/forms";
import {
  FILE_MUST_BE_SELECTED,
  TEMPLATE_MUST_BE_SELECTED,
} from "~/constants/strings";

export const importBook = values => {
  const errors = {};

  if (!values[AddressBookImportFields.ADDRESS_BOOK_FILE]) {
    errors.filename = FILE_MUST_BE_SELECTED;
    errors[AddressBookImportFields.ADDRESS_BOOK_FILE] = FILE_MUST_BE_SELECTED;
  }

  if (!values[AddressBookImportFields.TEMPLATE]) {
    errors[AddressBookImportFields.TEMPLATE] = TEMPLATE_MUST_BE_SELECTED;
  }

  return errors;
};
