import { ActionTypes } from "./actions";

export const initialState = {
  countries: [],
  networks: [],
  addresses: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_COUNTRIES.SUCCESS: {
      return {
        ...state,
        countries: action.payload.data,
      };
    }
    case ActionTypes.FETCH_NETWORKS.SUCCESS: {
      return {
        ...state,
        networks: action.payload.data,
      };
    }
    case ActionTypes.FETCH_ADDRESSES.SUCCESS: {
      return {
        ...state,
        addresses: action.payload.data,
      };
    }
    default:
      return state;
  }
};
