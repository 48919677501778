import api from "./api";

export const fetchAddressBookGroups = options =>
  api.get({
    path: "/addressbook/groups",
    options,
  });

export const fetchAddressBookGroupAddresses = (id, query, options) =>
  api.get({
    path: `/addressbook/groups/${id}/addresses`,
    query,
    options,
  });

export const fetchAddressBookGroupById = (id, options) =>
  api.get({
    path: `/addressbook/groups/${id}`,
    options,
  });

export const removeAddressBookGroupById = (id, options) =>
  api.delete({
    path: `/addressbook/groups/${id}`,
    options,
  });

export const createAddressBookGroup = (body, options) =>
  api.post({
    path: "/addressbook/groups",
    body,
    options,
  });

export const editAddressBookGroup = (id, body, options) =>
  api.put({
    path: `/addressbook/groups/${id}`,
    body,
    options,
  });
