import { useMemo } from "react";

import PropTypes from "prop-types";
import { compose } from "recompose";

import { Header, withUrlResolver } from "@dpdgroupuk/mydpd-app";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { COLLECTIONS_MENU, MENU } from "~/constants/analytics";

import { ADDRESS_BOOK, EXPORTS, GROUPS, PRODUCTS } from "./constants/strings";
import * as routes from "./router/constants";

const addressBookLinks = [
  {
    href: routes.ADDRESSES,
    name: ADDRESS_BOOK,
  },
  {
    href: routes.GROUPS,
    name: GROUPS,
  },
];

export const AppHeader = ({
  onSecondaryMenuSelect,
  onPrimaryMenuSelect,
  urlResolver,
  ...headerProps
}) => {
  const links = useMemo(
    () => [
      ...addressBookLinks,
      {
        href: urlResolver.getUrl("product-book.products"),
        name: PRODUCTS,
      },
      {
        href: routes.EXPORTS,
        name: EXPORTS,
      },
    ],
    [urlResolver]
  );

  return (
    <Header
      secondaryMenuItems={links}
      onPrimaryMenuSelect={onPrimaryMenuSelect}
      onSelectSecondaryMenu={onSecondaryMenuSelect}
      {...headerProps}
    />
  );
};

AppHeader.propTypes = {
  onPrimaryMenuSelect: PropTypes.func,
  onSecondaryMenuSelect: PropTypes.func,
  urlResolver: PropTypes.func,
};

const PRIMARY_INDEX_TO_ACTION_ID = {
  0: MENU.CLICK_MYDPD,
  1: MENU.CLICK_SHIPPING,
  2: MENU.CLICK_COLLECTIONS,
  3: MENU.CLICK_DELIVERIES,
  4: MENU.CLICK_SHOP_RETURNS,
  5: MENU.CLICK_ADDRESS_BOOK,
  6: MENU.CLICK_DEPOT_FINDER,
};

const SECONDARY_INDEX_TO_ACTION_ID = {
  0: COLLECTIONS_MENU.CLICK_CREATE_COLLECTION,
  1: COLLECTIONS_MENU.CLICK_SEARCH,
  2: COLLECTIONS_MENU.CLICK_DASHBOARD,
  3: COLLECTIONS_MENU.CLICK_WATCH_LIST,
};

export default compose(
  withTrack(MENU.LOAD),
  withTrackProps({
    onPrimaryMenuSelect: index => PRIMARY_INDEX_TO_ACTION_ID[index],
    onSecondaryMenuSelect: index => SECONDARY_INDEX_TO_ACTION_ID[index],
    onClickDpdLogo: MENU.CLICK_DPD_LOGO,
    onClickHelp: MENU.CLICK_HELP,
    onClickLogOff: MENU.CLICK_LOG_OFF,
  }),
  withUrlResolver
)(AppHeader);
