import { isArray } from "lodash";
import PropTypes from "prop-types";

import { Card, CommonStep } from "@dpdgroupuk/mydpd-ui";

const Step = ({
  step,
  title,
  helpModalTitle,
  children,
  help,
  footer,
  closeButtonText,
  confirmButtonText,
  confirmButtonVariant,
  closeButtonVariant,
  classes,
  rounded,
}) => (
  <CommonStep
    step={step}
    title={title}
    footer={footer}
    classes={classes}
    rounded={rounded}
    header={
      isArray(help) && (
        <Card.HelpIcon
          help={help}
          helpModalTitle={helpModalTitle}
          closeButtonText={closeButtonText}
          confirmButtonText={confirmButtonText}
          confirmButtonVariant={confirmButtonVariant}
          closeButtonVariant={closeButtonVariant}
        />
      )
    }
  >
    {children}
  </CommonStep>
);

Step.defaultProps = {
  classes: {},
  rounded: true,
};

Step.propTypes = {
  step: PropTypes.number,
  title: PropTypes.string,
  helpModalTitle: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  help: PropTypes.array,
  footer: PropTypes.elementType,
  closeButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmButtonVariant: PropTypes.string,
  closeButtonVariant: PropTypes.string,
  rounded: PropTypes.bool,
};

export default Step;
