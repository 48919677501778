import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import { createReducer } from "redux-orm";

import { combineAppReducer } from "@dpdgroupuk/mydpd-app";

import AddressBookReducer from "../pages/AddressBook/reducer";
import ExportAddressBookReducer from "../pages/ExportAddressBook/redux/reducer";
import orm from "./orm/orm";
import { toUppercase } from "./reduxForm/plugins";
import { ReferenceDataReducer } from "./reference";
import { UmsReducer } from "./ums";

export default combineReducers({
  form: form.plugin({
    ADDRESS_BOOK_FORM: toUppercase,
  }),
  orm: createReducer(orm),
  ui: combineReducers({
    addressBook: AddressBookReducer,
    exportAddressBook: ExportAddressBookReducer,
  }),
  app: combineAppReducer({
    ums: UmsReducer,
    referenceData: ReferenceDataReducer,
  }),
});
