import { useMemo } from "react";

import { get } from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { useColumnOrder } from "react-table/src/plugin-hooks/useColumnOrder";
import { useFlexLayout } from "react-table/src/plugin-hooks/useFlexLayout";
import { useResizeColumns } from "react-table/src/plugin-hooks/useResizeColumns";
import { compose } from "redux";
import { reduxForm, propTypes as reduxFormPropTypes } from "redux-form";

import {
  useUserHeaderStatePersist,
  withAppUser,
  withNotifier,
} from "@dpdgroupuk/mydpd-app";
import {
  Button,
  DndTable,
  withOverlay,
  withPrompt,
  withSnackbar,
} from "@dpdgroupuk/mydpd-ui";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import Step from "~/components/Step";
import {
  ADDRESS_GROUP_ENTRY_PAGE,
  CONFIRM_CANCEL_POP_UP,
} from "~/constants/analytics";
import { ADDRESS_COLUMNS } from "~/constants/columns";
import { AddressBookEntity, EDIT_ADDRESS_BOOK_GROUP } from "~/constants/forms";
import {
  ARE_YOU_SURE_YOU_WISH_TO_CANCEL,
  CANCEL,
  CANCEL_GROUP_EDIT,
  GROUP_ADDRESSES,
  GROUP_WAS_UPDATED,
  IN_GROUP_ADDRESSES,
  SAVE,
} from "~/constants/strings";
import withLoaderHandlers from "~/hocs/withLoaderHandlers";
import withPromptAnalytics from "~/hocs/withPromptAnalytics";
import { groupsValidators } from "~/models/validators";
import { ReferenceDataSelectors } from "~/redux/reference";
import { GROUPS } from "~/router";
import { getDeepKeys } from "~/utils/object";
import {
  getPathId,
  getQueryPagination,
  parseQuery,
  stringifyQuery,
} from "~/utils/query";

import { fetchGroupAddressesById, updateGroup } from "../../actions";
import CardStepFooter from "../../components/CardStepFooter";
import GroupForm from "../../components/GroupForm/GroupForm";
import {
  getAddressBookGroup,
  getFormState,
  getGroupAddresses,
} from "../../selectors";
import styles from "./../../Groups.module.scss";

const GroupAddresses = props => {
  const { addressesSearchPage, addressesSearchPageSize } = useMemo(
    () =>
      getQueryPagination(props.location, [
        "addressesSearchPage",
        "addressesSearchPageSize",
      ]),
    [props.location]
  );

  const groupId = useMemo(
    () => getPathId(props.match, "params.groupId"),
    [props.match]
  );

  const data = useMemo(
    () => get(props, "groupAddresses.results", []),
    [props?.groupAddresses?.results]
  );

  const columnOrder = useMemo(
    () => [
      AddressBookEntity.SHORT_NAME,
      AddressBookEntity.ADDRESS.POSTCODE,
      AddressBookEntity.ADDRESS.STREET,
      AddressBookEntity.IS_VALID,
    ],
    []
  );

  const defaultColumn = useMemo(
    () => ({
      minWidth: 80,
      width: 200,
      maxWidth: 400,
    }),
    []
  );

  return (
    <Step
      classes={{
        container: styles.cartContainer,
      }}
      step={2}
      title={IN_GROUP_ADDRESSES}
    >
      <GroupForm
        networks={props.networks}
        onSelect={props.onSelect}
        onChange={props.onChange}
        disabled={!groupId}
      />

      <DndTable
        data={data}
        columns={ADDRESS_COLUMNS}
        classes={{
          container: styles.tableContainer,
          table: styles.table,
          popover: styles.popover,
        }}
        tableHooks={[
          useFlexLayout,
          useResizeColumns,
          useColumnOrder,
          useUserHeaderStatePersist,
        ]}
        initialState={{
          columnOrder,
          selectedRowIds: props.groupId,
          storageKey: "addressbookGroup",
        }}
        isVisiblePopover
        defaultColumn={defaultColumn}
        onClickRow={props.onClickRow}
      >
        <DndTable.DndPaginator
          page={parseInt(addressesSearchPage)}
          totalCount={get(props, "groupAddresses.totalResults", 0)}
          pageSize={parseInt(addressesSearchPageSize)}
          onPrevious={props.onPrevious}
          onNext={props.onNext}
          onFirst={props.onFirst}
          onLast={props.onLast}
        />
      </DndTable>
      <CardStepFooter>
        <Button
          disabled={props.pristine}
          variant="dark"
          onClick={props.onCancel}
        >
          {CANCEL}
        </Button>
        <Button
          disabled={props.pristine || props.submitting || !props.valid}
          onClick={props.handleSubmit(props.onSubmit)}
        >
          {SAVE}
        </Button>
      </CardStepFooter>
    </Step>
  );
};

GroupAddresses.propTypes = {
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  onCancel: PropTypes.func,
  location: PropTypes.object,
  networks: PropTypes.array,
  valid: PropTypes.bool,
  submitting: PropTypes.bool,
  ...reduxFormPropTypes,
};

export default compose(
  withPrompt,
  withNotifier,
  withSnackbar,
  withPromptAnalytics,
  withRouter,
  withAppUser,
  withOverlay,
  connect(null, (dispatch, { history, location, notifier }) => ({
    fetchGroupAddresses: notifier.runAsync(
      (id, query) => dispatch(fetchGroupAddressesById(id, query)),
      { entityName: GROUP_ADDRESSES }
    ),
    resetNavigationState: (options = {}) => {
      history.push({
        pathname: GROUPS,
        search: location.search,
        ...options,
      });
    },
  })),
  withLoaderHandlers,
  connect(
    (state, { match }) => ({
      networks: ReferenceDataSelectors.getNetworksKeyValue(state),
      initialValues: getAddressBookGroup(
        state,
        getPathId(match, "params.groupId")
      ),
      groupAddresses: getGroupAddresses(
        state,
        getPathId(match, "params.groupId")
      ),
      formState: getFormState(state),
    }),
    (
      dispatch,
      {
        analyticsPrompt,
        history,
        location,
        overlay,
        snackbar,
        match,
        fetchGroupAddresses,
        resetNavigationState,
        notifier,
      }
    ) => {
      const changeFilter = (key, value) => {
        const query = parseQuery(location);
        history.push({
          search: stringifyQuery({
            ...query,
            [key]: value,
          }),
        });
      };

      const onNextOrPrev = overlay.showWhile(async targetPage => {
        const groupId = getPathId(match, "params.groupId");
        const { addressesSearchPageSize: searchPageSize } =
          parseQuery(location);

        await fetchGroupAddresses(groupId, {
          searchPage: targetPage,
          searchPageSize,
        });

        changeFilter("addressesSearchPage", targetPage);
      });

      return {
        onNext: onNextOrPrev,

        onPrevious: onNextOrPrev,

        onFirst: onNextOrPrev,

        onLast: onNextOrPrev,

        onCancel: () =>
          analyticsPrompt.showConfirmation({
            header: CANCEL_GROUP_EDIT,
            message: ARE_YOU_SURE_YOU_WISH_TO_CANCEL,
            trackProps: CONFIRM_CANCEL_POP_UP,
            onConfirm: () => resetNavigationState(),
          }),

        onSubmit: notifier.runAsync(
          async ({ groupId, ...values }, dispatch) => {
            try {
              overlay.show();
              await dispatch(
                updateGroup(
                  groupId,
                  {
                    ...values,
                    groupName: values.groupName.trim(),
                  },
                  dispatch
                )
              );

              snackbar.showSuccess({ message: GROUP_WAS_UPDATED });
            } finally {
              overlay.hide();
            }
          }
        ),
      };
    }
  ),
  reduxForm({
    form: EDIT_ADDRESS_BOOK_GROUP,
    validate: groupsValidators.groupValidator,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, _, props) => {
      const mappedErrors = getDeepKeys(errors);
      props?.notifier.scrollToError(mappedErrors);
    },
    onSubmitSuccess: async (values, dispatch, { resetNavigationState }) =>
      resetNavigationState(),
  }),
  withTrackProps({
    onCancel: ADDRESS_GROUP_ENTRY_PAGE.CLICK_CANCEL,
    onSubmit: ADDRESS_GROUP_ENTRY_PAGE.CLICK_SAVE,
    onChange: ADDRESS_GROUP_ENTRY_PAGE.ENTER_TEXT_GROUP_NAME,
    onSelect: ADDRESS_GROUP_ENTRY_PAGE.SELECT_AVAILABLE_SERVICES,
    onNext: ADDRESS_GROUP_ENTRY_PAGE.CLICK_NEXT,
    onPrevious: ADDRESS_GROUP_ENTRY_PAGE.CLICK_PREVIOUS,
    onFirst: ADDRESS_GROUP_ENTRY_PAGE.CLICK_FIRST,
    onLast: ADDRESS_GROUP_ENTRY_PAGE.CLICK_LAST,
  })
)(GroupAddresses);
