import {
  createActionTypes,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

export default createActionTypes("EXPORT_ADDRESS_BOOK", {
  SEARCH: createAsyncActionTypes("SEARCH"),
  GENERATE_FILE_URL: createAsyncActionTypes("GENERATE_FILE_URL"),
  EXPORT_ADDRESS_BOOK: createAsyncActionTypes("EXPORT_ADDRESS_BOOK"),
  GET_EXPORTER_ADDRESS_BOOK: createAsyncActionTypes(
    "GET_EXPORTER_ADDRESS_BOOK"
  ),
});
