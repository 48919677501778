import StatusCell from "../components/StatusCell";

export const ExportAddressBookEntity = {
  USER_ID: "userId",
  FILE_NAME: "fileName",
  CREATED_AT: "createdAt",
  LAST_UPDATED: "lastUpdated",
  STATUS: "status",
};

export const getActionExportAddressBookColumns = ({
  onDownloadFile,
  onClickRefresh,
}) => [
  {
    Header: "USER ID",
    accessor: ExportAddressBookEntity.USER_ID,
    width: 110,
  },
  {
    Header: "FILE NAME",
    accessor: ExportAddressBookEntity.FILE_NAME,
    width: 260,
  },
  {
    Header: "CREATED AT",
    accessor: ExportAddressBookEntity.CREATED_AT,
    width: 120,
  },
  {
    Header: "LAST UPDATED",
    accessor: ExportAddressBookEntity.LAST_UPDATED,
    width: 120,
  },
  {
    Header: "STATUS",
    accessor: ExportAddressBookEntity.STATUS,
    width: 140,
    // eslint-disable-next-line react/prop-types
    Cell: ({ row: { original } }) => (
      <StatusCell
        exportAddressBook={original}
        onClickDownload={() => onDownloadFile(original)}
        onClickRefresh={() => onClickRefresh(original)}
      />
    ),
  },
];
