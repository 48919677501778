import PropTypes from "prop-types";
import { propTypes } from "redux-form";

import Step from "~/components/Step";
import { ADDRESS_DETAILS, EDIT_ADDRESS } from "~/constants/strings";
import info from "~/models/info";

import ReadOnlyHeader from "../ReadOnlyHeader";
import styles from "./Card.module.scss";
import Form from "./Form";

const Card = props => {
  const { title, isReadonly, isDisabled, children, ...formProps } = props;

  const readOnlyHeader = (
    <span className={styles.title}>
      <span>{ADDRESS_DETAILS}</span>
      <ReadOnlyHeader />
    </span>
  );

  return (
    <Step
      step={2}
      title={isReadonly ? readOnlyHeader : title}
      help={info[formProps.form]}
      helpModalTitle={title}
    >
      <Form isReadonly={isReadonly} isDisabled={isDisabled} {...formProps} />
      {children}
    </Step>
  );
};

Card.defaultProps = {
  isDisabled: true,
  title: EDIT_ADDRESS,
};

Card.propTypes = {
  title: PropTypes.string,
  isDisabled: PropTypes.bool,
  isReadonly: PropTypes.bool,
  children: PropTypes.node,
  ...propTypes,
};

export default Card;
