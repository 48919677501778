import { truncate } from "lodash";
import { connect } from "react-redux";
import { autofill, touch } from "redux-form";

import { AddressBookEntity } from "~/constants/forms";
import { transformAddressValues } from "~/pages/AddressBook/models";

export default formName =>
  connect(null, dispatch => ({
    onPostcodeSelectionChange: ({
      postcode,
      organisation,
      street,
      locality,
      town,
      county,
      property,
    } = {}) => {
      const { addressLine1, addressLine2 } = transformAddressValues({
        street,
        locality,
        property,
      });

      dispatch(
        autofill(formName, AddressBookEntity.ADDRESS.POSTCODE, postcode)
      );
      dispatch(
        autofill(
          formName,
          AddressBookEntity.ADDRESS.ORGANISATION,
          truncate(organisation, {
            length: 35,
            separator: " ",
            omission: "",
          })
        )
      );
      dispatch(
        autofill(formName, AddressBookEntity.ADDRESS.STREET, addressLine1)
      );
      dispatch(
        autofill(formName, AddressBookEntity.ADDRESS.LOCALITY, addressLine2)
      );
      dispatch(autofill(formName, AddressBookEntity.ADDRESS.TOWN, town));
      dispatch(autofill(formName, AddressBookEntity.ADDRESS.COUNTY, county));
      dispatch(touch(formName, ...Object.values(AddressBookEntity.ADDRESS)));
    },
  }));
