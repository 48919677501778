import { Component } from "react";

import classNames from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";

import iconDragGroup from "~/assets/icons/iconDragGroup.svg";

import Card from "./Card";
import styles from "./DragAndDrop.module.scss";

export default class DragAndDrop extends Component {
  state = {
    active: undefined,
  };

  onDragStart = (ev, id, category) => {
    this.setState({
      active: category,
    });
    ev.dataTransfer.setData("id", id);
  };

  onDragOver = ev => {
    ev.preventDefault();
  };

  onDrop = (ev, cat) => {
    const id = ev.dataTransfer.getData("id");

    if (!id || !this.state.active) {
      ev.preventDefault();
      return;
    }

    this.setState({
      active: undefined,
    });

    // Avoid destination duplication
    if (
      cat === "destination" &&
      this.props[cat].find(({ key }) => key === id)
    ) {
      return;
    }

    this.props.onDrop(
      [...this.props.source, ...this.props.destination].find(
        ({ key }) => key === id
      ),
      cat
    );
  };

  render() {
    const tasks = {
      source: [],
      destination: [],
    };

    _.differenceBy(
      this.props.source,
      this.props.destination || [],
      "key"
    ).forEach(t => {
      tasks.source.push(
        <div
          className={classNames(
            styles.item,
            this.props.disabled && styles.disabledItem
          )}
          key={t.key}
          onDragStart={e => this.onDragStart(e, t.key, "source")}
          draggable
        >
          {t.value}
        </div>
      );
    });
    this.props.destination.forEach(t => {
      tasks.destination.push(
        <div
          className={classNames(
            styles.item,
            this.props.disabled && styles.disabledItem
          )}
          key={t.key}
          onDragStart={e => this.onDragStart(e, t.key, "destination")}
          draggable
        >
          {t.value}
        </div>
      );
    });

    return (
      <div className={classNames(styles.containerDrag)}>
        <Card
          disabled={this.props.disabled}
          active={this.state.active === "destination"}
          className={classNames(styles.dndCard)}
          label={"Available Groups"}
          helperText={"Drag across to add group"}
          onDragOver={e => this.onDragOver(e)}
          onDrop={e => {
            this.onDrop(e, "source");
          }}
        >
          {tasks.source}
        </Card>
        <img
          className={classNames(
            styles.dragIcon,
            this.props.disabled && styles.dragIconDisabled
          )}
          alt="img"
          src={iconDragGroup}
        />
        <Card
          disabled={this.props.disabled}
          className={classNames(styles.dndCard)}
          active={this.state.active === "source"}
          label={"In Groups"}
          helperText={"Associated Groups"}
          onDragOver={e => this.onDragOver(e)}
          onDrop={e => this.onDrop(e, "destination")}
        >
          {tasks.destination}
        </Card>
      </div>
    );
  }
}

DragAndDrop.propTypes = {
  source: PropTypes.array,
  destination: PropTypes.array,
  disabled: PropTypes.bool,
  onDrop: PropTypes.func,
};

DragAndDrop.defaultProps = {
  source: [],
  destination: [],
  onDrop: () => {},
};

export const ReduxifiedDragAndDrop = ({ input, ...inputProps }) => (
  <DragAndDrop
    {...inputProps}
    destination={input.value || []}
    source={inputProps.values}
    onDrop={(value, target) => {
      if (target === "destination") {
        const res = input.value ? [...input.value, value] : [value];
        input.onChange(res);
        inputProps.onDestinationChange(res);
      } else {
        const res = input.value.filter(({ key }) => key !== value.key);
        input.onChange(res);
        inputProps.onSourceChange(res);
      }
    }}
  />
);

ReduxifiedDragAndDrop.propTypes = {
  input: PropTypes.object,
};

ReduxifiedDragAndDrop.defaultProps = {
  onDestinationChange: () => {},
  onSourceChange: () => {},
};
