import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import { compose, lifecycle } from "recompose";
import { propTypes, reset } from "redux-form";

import { Button } from "@dpdgroupuk/mydpd-ui";

import { ADDRESS_BOOK_FORM } from "~/constants/forms";
import * as S from "~/constants/strings";
import BaseBook from "~/features/BaseBook/BaseBook";

import UpdatesAndSave from "../../../../components/UpdatesAndSave";
import AddressCard from "../../components/AddressCard";
import ValidationAndAssociatedGroups from "../../components/ValidationAndAssociatedGroups";
import styles from "./CreateAddress.module.scss";

const CreateAddress = props => (
  <>
    <BaseBook title={S.SEARCH_AND_SELECT_ADDRESS} {...props}>
      <Col md={6}>
        <AddressCard title={S.CREATE_ADDRESS} {...props}>
          <div className={classNames(styles.footer)}>
            <Button disabled={true} variant="danger">
              {S.DELETE_RECORD}
            </Button>
          </div>
        </AddressCard>
      </Col>
      <Col className="col-12">
        <ValidationAndAssociatedGroups {...props} />
      </Col>
    </BaseBook>
    <UpdatesAndSave {...props} />
  </>
);

CreateAddress.propTypes = {
  searchAddressBooks: PropTypes.func,
  addressBookGroups: PropTypes.arrayOf(PropTypes.object),
  ...propTypes,
};

export default compose(
  lifecycle({
    componentWillUnmount() {
      const { dispatch } = this.props;
      dispatch(reset(ADDRESS_BOOK_FORM));
    },
  })
)(CreateAddress);
