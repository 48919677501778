import { Field } from "redux-form";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import { phoneNumberNormalize } from "~/models/normalizers";

const PhoneInput = props => (
  <Field
    component={FormControl.Input}
    normalize={phoneNumberNormalize}
    {...props}
  />
);

export default PhoneInput;
