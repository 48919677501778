import { useCallback, useState } from "react";

import ExportModal from "~/pages/AddressBook/components/ExportModal";

const withExport = Component => props => {
  const [showExportModal, toggleExportModal] = useState(false);
  const openExportModal = useCallback(() => {
    toggleExportModal(true);
  }, []);
  const closeExportModal = useCallback(() => toggleExportModal(false), []);

  return (
    <>
      {showExportModal && <ExportModal show onClose={closeExportModal} />}
      <Component {...props} openExportModal={openExportModal} />
    </>
  );
};

export default withExport;
