import { join, without } from "lodash";
import { compose, withProps } from "recompose";

import { usePrompt } from "@dpdgroupuk/mydpd-ui";
import { withTracker } from "@dpdgroupuk/react-event-tracker";

export const trackPropTypes = ["PREV_INTERFACE_ID", "INTERFACE_ID", "LOAD"];

const validateTrackProps = trackProps => {
  const trackKeys = Object.keys(trackProps);

  const diff = without(trackPropTypes, ...trackKeys);

  if (diff.length) {
    throw new Error(
      `The next "trackProps" must be defined: "${join(diff, ", ")}"`
    );
  }
};

export default compose(
  withTracker,
  withProps(props => {
    const { showConfirmation, showConfirmationDelete, ...rest } = usePrompt();

    const onComplete = (trackProps, logEvent) => {
      logEvent && props.tracker.logEvent(logEvent);
      props.tracker.setInterfaceId(trackProps.PREV_INTERFACE_ID);
    };

    const onShow = show => options => {
      const { trackProps, onConfirm, onReject } = options;
      validateTrackProps(trackProps);

      props.tracker.setInterfaceId(trackProps.INTERFACE_ID);
      props.tracker.logEvent(trackProps.LOAD);
      show({
        ...options,
        onConfirm: () => {
          onComplete(trackProps, trackProps.ON_CLICK_YES);
          return onConfirm && onConfirm();
        },
        onReject: () => {
          onComplete(trackProps, trackProps.ON_CLICK_NO);
          return onReject && onReject();
        },
      });
    };

    return {
      ...props,
      analyticsPrompt: {
        ...rest,
        showConfirmation: onShow(showConfirmation),
        showConfirmationDelete: onShow(showConfirmationDelete),
      },
    };
  })
);
