import { ORM } from "redux-orm";

import { AddressBook } from "./AddressBook/models";
import { ExportAddressBook } from "./ExportAddressBook/models";
import { Group } from "./Groups/models";

const orm = new ORM({
  stateSelector: state => state.orm,
});

orm.register(AddressBook);
orm.register(Group);
orm.register(ExportAddressBook);

export default orm;
