import {
  createActionTypes,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

export default createActionTypes("GROUPS", {
  CREATE: createAsyncActionTypes("CREATE"),
  FETCH: createAsyncActionTypes("FETCH"),
  FETCH_ADDRESSES: createAsyncActionTypes("FETCH_ADDRESSES"),
  GET: createAsyncActionTypes("GET"),
  DELETE: createAsyncActionTypes("DELETE"),
  UPDATE: createAsyncActionTypes("UPDATE"),
  CLEAR_FETCHED_ADDRESSES: "CLEAR_FETCHED_ADDRESSES",
});
