import Joi from "joi";

import { Fields, SEARCH_CRITERIA_FIELD } from "~/constants/forms";

import {
  contactName,
  countryCode,
  locality,
  organisation,
  postcode,
  shortName,
  street,
  town,
} from "./commonSchema";

export default Joi.object({
  searchCriteriaField: Joi.string(),
  searchCriteriaValue: Joi.when(Joi.ref(SEARCH_CRITERIA_FIELD), {
    is: Fields.POSTCODE,
    then: postcode,
    otherwise: Joi.when(Joi.ref(SEARCH_CRITERIA_FIELD), {
      is: Fields.ORGANISATION,
      then: organisation,
      otherwise: Joi.when(Joi.ref(SEARCH_CRITERIA_FIELD), {
        is: Fields.SHORT_NAME,
        then: shortName,
        otherwise: Joi.when(Joi.ref(SEARCH_CRITERIA_FIELD), {
          is: Fields.COUNTRY_CODE,
          then: countryCode,
          otherwise: Joi.when(Joi.ref(SEARCH_CRITERIA_FIELD), {
            is: Fields.STREET,
            then: street,
            otherwise: Joi.when(Joi.ref(SEARCH_CRITERIA_FIELD), {
              is: Fields.LOCALITY,
              then: locality,
              otherwise: Joi.when(Joi.ref(SEARCH_CRITERIA_FIELD), {
                is: Fields.TOWN,
                then: town,
                otherwise: Joi.when(Joi.ref(SEARCH_CRITERIA_FIELD), {
                  is: Fields.CONTACT_NAME,
                  then: contactName,
                }),
              }),
            }),
          }),
        }),
      }),
    }),
  }),
});
