const createPageMeta = (interfaceId, rest = {}) => ({
  INTERFACE_ID: interfaceId,
  ...rest,
});

export const ADDRESS_BOOK_ENTRY_PAGE = createPageMeta("2ddXju6C48kIGH5M", {
  // List view
  LOAD: "yK13ju6C48kIGH6_",
  SELECT_ROW: "An1jYe6C48kIGE9s",
  ON_CLICK_SEARCH: "FsCYQe6C48kIGHKI",
  ON_CLICK_HIDE_DELIVERY_ADDRESS: "erOiQe6C48kIGEvs",
  ON_CLICK_IMPORT: "BJqJie6C48kIGHm7",
  ON_CLICK_FIRST: "H0jBQe6C48kIGCwr",
  ON_CLICK_LAST: "Bh3VQe6C48kIGGOf",
  ON_CLICK_NEW: "p1XKYe6C48kIGEpc",
  ON_CLICK_NEXT: "00SNQe6C48kIGLJD",
  ON_CLICK_PREV: "N6L1Qe6C48kIGK9P",
  ON_CLICK_DELETE_ALL: "MU4Kge6C48kIGFIT",
  ON_CLICK_DELETE: "0bsOMe6C48kIGNoK",
  // Edit view
  ON_ENTER_SHORT_NAME: "xU.64e6C48kIGML8",
  ON_SELECT_COUNTRY: "Xaj_Me6C48kIGEPr",
  ON_ENTER_POSTCODE: "QQwd0e6C48kIGFGD",
  ON_ENTER_ORGANISATION: "BQwd0e6C48kIGFGL",
  ON_ENTER_ADDRESS_LINE_1: "bQwd0e6C48kIGFGT",
  ON_ENTER_ADDRESS_LINE_2: "Hwwd0e6C48kIGFGb",
  ON_ENTER_TOWN: "SIwd0e6C48kIGFGj",
  ON_ENTER_COUNTY: "VIwd0e6C48kIGFGr",
  ON_ENTER_CONTACT: "Qowd0e6C48kIGFGz",
  ON_ENTER_TELEPHONE: "Wowd0e6C48kIGFG7",
  ON_ENTER_DELIVERY_INSTRUCTION: "ZYwd0e6C48kIGFHT",
  ON_SELECT_IS_DEFAULT: "3hnqMe6C48kIGHO9",
  ON_ENTER_NOTIFICATION_MOBILE: "Lowd0e6C48kIGFHD",
  ON_ENTER_NOTIFICATION_EMAIL: "cYwd0e6C48kIGFHL",
  ON_ENTER_SHIPPING_REF_1: "fYwd0e6C48kIGFHb",
  ON_ENTER_SHIPPING_REF_2: "G4wd0e6C48kIGFHj",
  ON_ENTER_SHIPPING_REF_3: "D4wd0e6C48kIGFHr",
  ON_ENTER_VAT_NUMBER: "EEwd0e6C48kIGFHz",
  ON_SELECT_ADDRESS_TYPE: "fSvoMe6C48kIGHOL",
  ON_SELECT_ASSOCIATED_GROUPS: "A4.6Me6C48kIGGlb",
  ON_SELECT_AVAILABLE_GROUPS: "j.HaMe6C48kIGOO9",
  ON_CLICK_SAVE: "2dfgse6C48kIGLUp",
  ON_SELECT_COLUMN_HEADER_ITEM: "vEmBie6C48kIGAXl",
});

export const ADDRESS_GROUP_ENTRY_PAGE = createPageMeta("h97OZe6C48kIGNx5", {
  LOAD: "tUkuZe6C48kIGN0x",
  SELECT_GROUP_LIST: "q7M7Ve6C48kIGLtm",
  CLICK_NEW: "UAelZe6C48kIGBHR",
  CLICK_DELETE: "x_ulZe6C48kIGBHL",
  CLICK_CANCEL: "FAelZe6C48kIGBHX",
  CLICK_SAVE: "FgelZe6C48kIGBHj",
  ENTER_TEXT_GROUP_NAME: "zO4Fre6C48kIGDRG",
  SELECT_AVAILABLE_SERVICES: "wO4Fre6C48kIGDRA",
  CLICK_NEXT: "jdri1e6C48kIGLtA",
  CLICK_PREVIOUS: ".9ri1e6C48kIGLtG",
  CLICK_FIRST: "sdri1e6C48kIGLs6",
  CLICK_LAST: "oDri1e6C48kIGLtM",
});

export const FIND_POSTCODE_MODAL = createPageMeta("MDynjXaD.AAAAQW6", {
  LOAD: "XT93jXaD.AAAAZ5S",
  CLICK_ADDRESS: "mYcGTXaD.AAAAQ7v",
  CLICK_CLEAR: "iD4PjXaD.AAAAZ75",
  CLICK_FIND_POSTCODE: "8d0PjXaD.AAAAZ8F",
});

export const IMPORT_ADDRESS_BOOK_MODAL = createPageMeta("kVXJie6C48kIGHpa", {
  LOAD: "lyQ1qe6C48kIGEY7",
  CLICK_CLOSE: "vqTSSe6C48kIGHcW",
  CLICK_SELECT_FILE: "8yTSSe6C48kIGHcK",
  CLICK_UPLOAD: "iqTSSe6C48kIGHcQ",
  SELECT_EMPTY_ADDRESS: "eBu8Se6C48kIGKFf",
  SELECT_GROUP_IMPORT_DEFAULT: "ohu8Se6C48kIGKFl",
  SELECT_GROUP_IMPORT_UPDATE: "thu8Se6C48kIGKFr",
  SELECT_IMPORT_NEW_ONLY: "Ceu8Se6C48kIGKFT",
  SELECT_OVERWRITE_ADDRESSES: "r.u8Se6C48kIGKFZ",
  SELECT_TEMPLATE: "KRu8Se6C48kIGKFx",
});

export const EXPORT_ADDRESS_BOOK_MODAL = createPageMeta(
  "exportAddressBookModal",
  {
    CLICK_CLOSE: "clickModalClose",
    CLICK_EXPORT: "clickExportClose",
    SELECT_TEMPLATE: "clickSelectTemplate",
  }
);

export const SEARCH_RESULT_LIST = createPageMeta("yTFtdnaD.AAAAR9e");

export const MENU = {
  LOAD: "nb_PSXaD.AAAAdCJ",
  CLICK_ADDRESS_BOOK: "JpWuyXaD.AAAARFD",
  CLICK_COLLECTIONS: "2tiOyXaD.AAAAeTY",
  CLICK_DPD_LOGO: "3WQ5yXaD.AAAATDC",
  CLICK_DELIVERIES: "Op9OyXaD.AAAAeo4",
  CLICK_DEPOT_FINDER: "z1euyXaD.AAAARFo",
  CLICK_HELP: "n9FuyXaD.AAAARGk",
  CLICK_LOG_OFF: "h8tuyXaD.AAAARG6",
  CLICK_MYDPD: "ISlmyXaD.AAAAd2H",
  CLICK_SHIPPING: "UKB2yXaD.AAAAeF3",
  CLICK_SHOP_RETURNS: "vmquyXaD.AAAAREm",
};

export const COLLECTIONS_MENU = {
  CLICK_SEARCH: "yYWVyXaD.AAAAToT",
  CLICK_DASHBOARD: "Vy5zyXaD.AAAAUK3",
  CLICK_WATCH_LIST: "zQ0eTXaD.AAAASg0",
  CLICK_CREATE_COLLECTION: "EWdzyXaD.AAAAUMW",
};

export const FOOTER = {
  LOAD: "PGqBTXaD.AAAAUAH",
  CLICK_TERMS_AND_CONDITIONS: "Y_uRTXaD.AAAAUXr",
};

export const SEARCH = {
  LOAD: "LD8ap46D.AAAAYev",
  CLICK_SEARCH: "zrDAM3aD.AAAAYOh",
  CLICK_SEARCH_SELECTOR: "VF.AM3aD.AAAAYD_",
};

// Address book
export const DELETE_ALL_CONFIRMATION_POP_UP = createPageMeta(
  "uqDmge6C48kIGFbV",
  {
    PREV_INTERFACE_ID: ADDRESS_BOOK_ENTRY_PAGE.INTERFACE_ID,
    ON_CLICK_CLOSE: "7VlWge6C48kIGFdW",
    ON_CLICK_NO: "FPfjQe6C48kIGBv5",
    ON_CLICK_YES: "910TQe6C48kIGB1O",
    LOAD: "8aGWge6C48kIGFc1",
  }
);

export const DELETE_CONFIRMED_POP_UP = createPageMeta("7G9HMe6C48kIGEg2", {
  PREV_INTERFACE_ID: DELETE_ALL_CONFIRMATION_POP_UP.INTERFACE_ID,
  ON_CLICK_YES: "HA_nMe6C48kIGHDH",
  LOAD: "QE.nMe6C48kIGHB2",
});

export const IMPORT_RESULT_POP_UP = createPageMeta("oc1sae6C48kIGCsA", {
  LOAD: "v6J6ae6C48kIGMyL",
  ON_CLICK_CLOSE: "owIyae6C48kIGHdV",
  ON_CLICK_YES: "BoIyae6C48kIGHdh",
  CLICK_SORT_ICON: "BIIyae6C48kIGHdb",
});

// Address group
export const CONFIRM_CANCEL_POP_UP = createPageMeta("koUDZe6C48kIGADn", {
  PREV_INTERFACE_ID: ADDRESS_GROUP_ENTRY_PAGE.INTERFACE_ID,
  LOAD: "eaCzVe6C48kIGFg3",
  ON_CLICK_NO: "dQ.zVe6C48kIGFhB",
  ON_CLICK_YES: "RI.zVe6C48kIGFhN",
});

export const DELETE_SUCCESSFUL_POP_UP = createPageMeta("lePBre6C48kIGMkF", {
  PREV_INTERFACE_ID: ADDRESS_GROUP_ENTRY_PAGE.INTERFACE_ID,
  LOAD: "o8A81e6C48kIGH5h",
  ON_CLICK_YES: "RI.fsA81e6C48kIGH5b",
});

export const GROUP_SAVE_SUCCESSFUL_POP_UP = createPageMeta("FzWure6C48kIGCfJ", {
  PREV_INTERFACE_ID: ADDRESS_GROUP_ENTRY_PAGE.INTERFACE_ID,
  LOAD: "CM8w1e6C48kIGFHS",
  ON_CLICK_YES: "ItdQ1e6C48kIGFGc",
});
