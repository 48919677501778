import { get, isEmpty, isEqual, isNil, omit } from "lodash";
import { findIndex } from "lodash/array";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { compose, lifecycle, withProps } from "recompose";
import {
  autofill,
  change,
  reduxForm,
  reset,
  resetSection,
  stopSubmit,
  touch,
} from "redux-form";

import { withAppUser, withNotifier } from "@dpdgroupuk/mydpd-app";
import {
  withLoader,
  withOverlay,
  withPrompt,
  withSnackbar,
} from "@dpdgroupuk/mydpd-ui";
import { trackProps, withTrack } from "@dpdgroupuk/react-event-tracker";

import {
  ADDRESS_BOOK_ENTRY_PAGE,
  DELETE_ALL_CONFIRMATION_POP_UP,
} from "~/constants/analytics";
import { ADDRESS_COLUMNS } from "~/constants/columns";
import {
  ADDRESS_BOOK_FORM,
  AddressBookEntity,
  AddressBookFilterOptionsList,
  BOOK_SEARCH_FORM,
  NOTIFICATION_DETAILS,
  SEARCH_CRITERIA_VALUE,
} from "~/constants/forms";
import { GB } from "~/constants/strings";
import * as S from "~/constants/strings";
import withAddressBookFormAnalytics from "~/hocs/withAddressBookFormAnalytics";
import withExportsAddressBook from "~/hocs/withExportsAddressBook";
import withImportsAddressBook from "~/hocs/withImportsAddressBook";
import withLoaderHandlers from "~/hocs/withLoaderHandlers";
import withPostcodeAutocomplete from "~/hocs/withPostcodeAutocomplete";
import withPromptAnalytics from "~/hocs/withPromptAnalytics";
import { ADDRESS_BOOK_TYPES } from "~/models/enum";
import { convertToBool } from "~/models/normalizers";
import { addressValidators } from "~/models/validators";
import { UmsSelectors } from "~/redux";
import { AddressBookSelectors, GroupsActions } from "~/redux/orm";
import { ReferenceDataActions } from "~/redux/reference";
import { ADDRESSES, CREATE_ADDRESS, EDIT_ADDRESS } from "~/router";
import { getErrorMessage } from "~/utils/error";
import { getDeepKeys, getValue, toUppercaseValues } from "~/utils/object";
import {
  getPathId,
  getQueryFilters,
  omitLocationSearch,
  parseQuery,
} from "~/utils/query";

import * as AddressBookActions from "./actions";
import CreateAddressBook from "./containers/CreateAddress";
import EditAddressBook from "./containers/EditAddress";
import {
  formatServerAddress,
  getInitialAddressState,
  getSearchQuery,
  isAddressbookReadonly,
} from "./models";
import {
  getAddressBooksTotalResults,
  getAddressbookType,
  getAllowedFields,
  getCountries,
  getCountryCode,
  getRequiredFields,
  getSearchFormErrors,
  getSelectedCountry,
  isUserReadonlyPermissions,
} from "./selectors";

const AddressBook = props => (
  <Switch>
    <Route
      path={CREATE_ADDRESS}
      render={() => <CreateAddressBook {...props} />}
    />
    <Route path={EDIT_ADDRESS} render={() => <EditAddressBook {...props} />} />
    <Route
      render={() => (
        <CreateAddressBook
          key={true} // Re-render view to stop autocomplete
          isReadonly={true}
          {...props}
        />
      )}
    />
  </Switch>
);

AddressBook.propTypes = {
  authUser: PropTypes.object,
  requiredFields: PropTypes.object,
  allowedFields: PropTypes.object,
  searchAddressBooks: PropTypes.func,
  onIsBusinessChange: PropTypes.func,
  addressBookGroups: PropTypes.arrayOf(PropTypes.object),
};

export default compose(
  withPrompt,
  withAppUser,
  withOverlay,
  withImportsAddressBook,
  withExportsAddressBook,
  withPromptAnalytics,
  withNotifier,
  withSnackbar,
  connect(state => ({
    searchFormErrors: getSearchFormErrors(state),
  })),
  connect(
    (state, { match, location, appUser }) => {
      const queryStringValues = parseQuery(location).values;
      const addressBookId = getPathId(match, "params.id");
      let addressBook;
      const isEdit = addressBookId && location.pathname !== CREATE_ADDRESS;

      if (isEdit) {
        addressBook = toUppercaseValues(
          AddressBookSelectors.getAddressBook(state, addressBookId)
        );

        // handle existed addressBooks without isBusiness value
        if (isNil(getValue(addressBook, AddressBookEntity.IS_BUSINESS))) {
          // @see: second question from https://geopost.jira.com/wiki/spaces/CSHIP/pages/3450142723/NI+Protocol+-+Sprint+1
          addressBook = {
            ...omit(addressBook, [AddressBookEntity.AT_RISK]),
            [AddressBookEntity.IS_BUSINESS]: false,
          };
        }
      } else {
        addressBook = {
          addressType: ADDRESS_BOOK_TYPES.DELIVERY,
          address: {
            countryCode: S.GB,
          },
          readOnly: false,
          isBusiness: false,
        };
      }
      const initialValues = getInitialAddressState(
        addressBook,
        queryStringValues
      );
      const preferences = UmsSelectors.getPreferences(state);

      return {
        initialValues,
        isEdit,
        addressBookId: isEdit ? addressBookId : undefined,
        addressType: getAddressbookType(state),
        hasInitialUrlValues: !!queryStringValues,
        records: AddressBookSelectors.getAddressBooks(state),
        countries: getCountries(state),
        totalResults: getAddressBooksTotalResults(state),
        requiredFields: getRequiredFields(state),
        isUserReadonlyPermissions: isUserReadonlyPermissions(state),
        countryCode: getCountryCode(state),
        selectedCountry: getSelectedCountry(state),
        isReadonly: isEdit
          ? isAddressbookReadonly(addressBook, preferences, appUser)
          : isUserReadonlyPermissions(state),
        isDisabled: !addressBookId,
        isDisabledAddressType: location.pathname !== CREATE_ADDRESS,
        allowedFields: getAllowedFields(state),
      };
    },
    (
      dispatch,
      {
        location,
        notifier,
        snackbar,
        history,
        overlay,
        analyticsPrompt,
        match,
        searchFormErrors,
      }
    ) => {
      const queryStringValues = parseQuery(location).values;
      const searchAddressBooks = async fetchOptions => {
        if (isEmpty(searchFormErrors)) {
          const queryRequest = getSearchQuery(location);
          try {
            return await dispatch(
              AddressBookActions.searchAddressBooks(queryRequest, fetchOptions)
            );
          } catch (e) {
            dispatch(
              stopSubmit(BOOK_SEARCH_FORM, {
                SEARCH_CRITERIA_VALUE: getErrorMessage(e, S.PRODUCTS),
              })
            );
            dispatch(touch(BOOK_SEARCH_FORM, SEARCH_CRITERIA_VALUE));
          }
        } else {
          dispatch(touch(BOOK_SEARCH_FORM, SEARCH_CRITERIA_VALUE));
        }
      };

      return {
        searchAddressBooks,
        onCountryChange: ({ value }) => {
          dispatch(
            autofill(
              ADDRESS_BOOK_FORM,
              AddressBookEntity.ADDRESS.COUNTRY_CODE,
              value
            )
          );

          // @see: https://it.dpduk.live/version/customer-shipping/ni-protocol-shipping/sprint-1/diag_7dsSbvGGAqCIPVhB.html
          if (value !== GB) {
            dispatch(change(ADDRESS_BOOK_FORM, AddressBookEntity.UKIMS_NUMBER));
            dispatch(
              change(ADDRESS_BOOK_FORM, AddressBookEntity.AT_RISK, false)
            );
          }
        },
        onAddressTypeChange: value => {
          const fields = [NOTIFICATION_DETAILS];

          if (value === ADDRESS_BOOK_TYPES.RETURN) {
            dispatch(
              change(ADDRESS_BOOK_FORM, AddressBookEntity.IS_BUSINESS, false)
            );
            fields.push(
              AddressBookEntity.AT_RISK,
              AddressBookEntity.EORI_NUMBER,
              AddressBookEntity.VAT_NUMBER,
              AddressBookEntity.UKIMS_NUMBER
            );
          }
          dispatch(resetSection(ADDRESS_BOOK_FORM, ...fields));
        },
        fetchAddressBookById: async (addressBookId, addressType) => {
          try {
            overlay.show();
            return await dispatch(
              AddressBookActions.fetchAddressBookById(
                addressBookId,
                addressType
              )
            );
          } finally {
            overlay.hide();
          }
        },
        fetchCountries: notifier.runAsync(
          () => dispatch(ReferenceDataActions.fetchCountries()),
          { entityName: S.COUNTRIES }
        ),
        fetchAddressBookGroups: notifier.runAsync(
          fetchOptions => dispatch(GroupsActions.fetchGroups(fetchOptions)),
          { entityName: S.ADDRESS_BOOK_GROUPS }
        ),
        onClickNew: () => {
          dispatch(reset(ADDRESS_BOOK_FORM));
          history.push({
            pathname: CREATE_ADDRESS,
            search: omitLocationSearch(location.search, ["values"]),
          });
        },
        onSubmit: notifier.runAsync(
          async (values, dispatch, { overlay, history, location }) => {
            try {
              overlay.show();
              const { addressBookId, ...rest } = values;
              const request = formatServerAddress(rest);

              if (addressBookId) {
                await dispatch(
                  AddressBookActions.updateAddressBook(addressBookId, request)
                );
              } else {
                await dispatch(AddressBookActions.createAddressBook(request));
              }
              history.push({
                pathname: ADDRESSES,
                search: omitLocationSearch(location.search, ["values"]),
                state: {
                  forceReload: true,
                },
              });

              snackbar.showSuccess({
                message: addressBookId
                  ? S.ADDRESS_BOOK_WAS_UPDATED
                  : S.ADDRESS_BOOK_WAS_CREATED,
              });
            } finally {
              overlay.hide();
            }
          }
        ),
        deleteAll: async () => {
          overlay.show();
          try {
            const { type = ADDRESS_BOOK_TYPES.DELIVERY } =
              getQueryFilters(location);
            await dispatch(
              AddressBookActions.deleteAddressBook({
                type: ADDRESS_BOOK_TYPES.RETURN,
              })
            );

            parseInt(type) === parseInt(ADDRESS_BOOK_TYPES.DELIVERY) &&
              (await dispatch(
                AddressBookActions.deleteAddressBook({
                  type: ADDRESS_BOOK_TYPES.DELIVERY,
                })
              ));
            await searchAddressBooks();
            history.push({
              pathname: ADDRESSES,
              search: omitLocationSearch(location.search, ["values"]),
            });
          } finally {
            overlay.hide();
          }
        },
        onDeleteClick: ({ addressType, initialValues }) =>
          analyticsPrompt.showConfirmationDelete({
            header: S.CONFIRM_ADDRESS_DELETION,
            message: S.ARE_YOU_SURE_TO_DELETE_ADDRESSBOOK_ENTRY,
            footer: S.THERE_IS_NO_WAY_TO_RECOVER,
            trackProps: DELETE_ALL_CONFIRMATION_POP_UP,
            closeButtonText: S.CANCEL,
            confirmButtonText: S.CONFIRM,
            onConfirm: notifier.runAsync(async () => {
              await dispatch(
                AddressBookActions.deleteAddressBookById(
                  getPathId(match, "params.id"),
                  {
                    addressBookType: addressType,
                  }
                )
              );
              setTimeout(() => {
                const shortName =
                  initialValues.shortName.length > 30
                    ? initialValues.shortName.substr(0, 30) + "..."
                    : initialValues.shortName;

                snackbar.showSuccess({
                  message: `Your address (${shortName}) has been deleted successfully`,
                });

                // TODO: add analytics for DELETE_CONFIRMED_POP_UP
              }, 0);

              history.push({
                pathname: ADDRESSES,
                search: omitLocationSearch(location.search, ["values"]),
                state: {
                  forceReload: true,
                },
              });
            }),
          }),
        shouldAsyncValidate: params => {
          if (!params.syncValidationPasses) {
            return false;
          }
          switch (params.trigger) {
            case "blur":
            case "change":
              // blurring or changing
              return true;
            case "submit":
              // submitting, so only async validate if form is dirty or was never initialized
              // conversely, DON'T async validate if the form is pristine just as it was
              // initialized
              return (
                !!queryStringValues || !params.pristine || !params.initialized
              );
            default:
              return false;
          }
        },
        onIsBusinessChange: value => {
          if (convertToBool(value)) {
            dispatch(
              change(ADDRESS_BOOK_FORM, AddressBookEntity.PID_NUMBER, "")
            );
          } else {
            dispatch(
              change(ADDRESS_BOOK_FORM, AddressBookEntity.AT_RISK, false)
            );
            dispatch(
              change(ADDRESS_BOOK_FORM, AddressBookEntity.EORI_NUMBER, "")
            );
            dispatch(
              change(ADDRESS_BOOK_FORM, AddressBookEntity.VAT_NUMBER, "")
            );
            dispatch(
              change(ADDRESS_BOOK_FORM, AddressBookEntity.UKIMS_NUMBER, "")
            );
          }
        },
      };
    }
  ),
  withProps(
    ({
      prompt,
      notifier,
      deleteAll,
      snackbar,
      history,
      fetchAddressBookById,
      location,
      countries,
      isEdit,
      records,
      match,
    }) => ({
      ...(isEdit && {
        initialSelectedRowIds: {
          [findIndex(
            records,
            row => row.addressBookId === match.params.id
          )]: true,
        },
      }),
      onClickDeleteAll: () =>
        prompt.showConfirmationDelete({
          header: S.CONFIRM_ADDRESS_BOOK_DELETION,
          message: S.ARE_YOU_SURE_TO_DELETE_ALL_ADDRESSBOOK,
          footer: S.THERE_IS_NO_WAY_TO_RECOVER,
          closeButtonText: S.CANCEL,
          confirmButtonText: S.CONFIRM,
          onConfirm: notifier.runAsync(() =>
            deleteAll().then(() => {
              snackbar.showSuccess({ message: S.ADDRESSES_DELETED });
              history.push({
                pathname: ADDRESSES,
              });
            })
          ),
        }),
      onClickRow: async row => {
        const {
          original: { addressBookId, addressType },
        } = row;
        if (match.params.id !== addressBookId) {
          try {
            await fetchAddressBookById(addressBookId, addressType);
            history.push({
              pathname: `${ADDRESSES}/${addressBookId}`,
              search: omitLocationSearch(location.search, ["values"]),
            });
          } catch (err) {
            snackbar.showAlert({ message: S.ADDRESSBOOK_NOT_FOUND });
          }
        }
      },
      isInvalidRow: row =>
        !isEmpty(
          addressValidators.addressBookValidator(row, {
            countries,
          })
        ),
      useTypeCheckbox: true,
      typeCheckboxLabel: S.HIDE_DELIVERY_ADDRESS,
      columns: ADDRESS_COLUMNS,
      columnOrder: [
        AddressBookEntity.SHORT_NAME,
        AddressBookEntity.ADDRESS.POSTCODE,
        AddressBookEntity.ADDRESS.STREET,
        AddressBookEntity.IS_VALID,
      ],
      filterOptionsList: AddressBookFilterOptionsList,
      trackProps: {
        openImportModal: ADDRESS_BOOK_ENTRY_PAGE.ON_CLICK_IMPORT,
        onClickRow: ADDRESS_BOOK_ENTRY_PAGE.SELECT_ROW,
        onSearchTextChange: ADDRESS_BOOK_ENTRY_PAGE.ON_CLICK_SEARCH,
        onDefaultAddressChange:
          ADDRESS_BOOK_ENTRY_PAGE.ON_CLICK_HIDE_DELIVERY_ADDRESS,
        onFirst: ADDRESS_BOOK_ENTRY_PAGE.ON_CLICK_FIRST,
        onLast: ADDRESS_BOOK_ENTRY_PAGE.ON_CLICK_LAST,
        onNext: ADDRESS_BOOK_ENTRY_PAGE.ON_CLICK_NEXT,
        onPrevious: ADDRESS_BOOK_ENTRY_PAGE.ON_CLICK_PREV,
        onClickDeleteAll: ADDRESS_BOOK_ENTRY_PAGE.ON_CLICK_DELETE_ALL,
        onClickNew: ADDRESS_BOOK_ENTRY_PAGE.ON_CLICK_NEW,
      },
    })
  ),
  withLoaderHandlers,
  reduxForm({
    form: ADDRESS_BOOK_FORM,
    shouldError: () => true,
    enableReinitialize: true,
    destroyOnUnmount: false,
    validate: addressValidators.addressBookValidator,
    onSubmitFail: (errors, dispatch, _, props) => {
      const mappedErrors = getDeepKeys(errors);
      props?.notifier.scrollToError(mappedErrors);
    },
  }),
  withPostcodeAutocomplete(ADDRESS_BOOK_FORM),
  withAddressBookFormAnalytics,
  withLoader({
    loadFn: ({ fetchCountries, fetchAddressBookGroups }, fetchOptions) =>
      Promise.all([fetchCountries(), fetchAddressBookGroups(fetchOptions)]),
  }),
  lifecycle({
    async componentDidUpdate(prevProps) {
      const query = getSearchQuery(this.props.location);
      const prevQuery = getSearchQuery(prevProps.location);
      const forceReload =
        !isEqual(query, prevQuery) ||
        get(this.props.location.state, "forceReload");

      if (forceReload) {
        this.props.history.replace({
          pathname: this.props.location.pathname,
          search: this.props.location.search,
          state: {
            forceReload: false,
          },
        });
        this.props.overlay.show();
        await this.props.searchAddressBooks();
        this.props.overlay.hide();
      }
    },
  }),
  withTrack(trackProps(ADDRESS_BOOK_ENTRY_PAGE))
)(AddressBook);
