import {
  GROUP_NAME_MAX,
  GROUP_NAME_MUST_BE_ENTERED,
  NETWORK_MUST_BE_ENTERED,
} from "~/constants/strings";

export const groupValidator = values => {
  const errors = {};

  if (!values.groupName || !values.groupName.trim()) {
    errors.groupName = GROUP_NAME_MUST_BE_ENTERED;
  }

  if (values.groupName && values.groupName.trim().length > 35) {
    errors.groupName = GROUP_NAME_MAX;
  }

  if (!values.networkCode) {
    errors.networkCode = NETWORK_MUST_BE_ENTERED;
  }

  return errors;
};
