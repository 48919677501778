import { EDIT_ADDRESS_BOOK_GROUP } from "~/constants/forms";
import { GroupsSelectors } from "~/redux/orm";

export const getFormState = state =>
  state && state.form && state.form[EDIT_ADDRESS_BOOK_GROUP];

export const getAddressBookGroup = GroupsSelectors.getAddressBookGroup;

export const getGroupAddresses = GroupsSelectors.getGroupAddresses;

export const getAddressBookGroupsKeyValue =
  GroupsSelectors.getAddressBookGroupsKeyValue;
