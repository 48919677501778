import classNames from "classnames";
import PropTypes from "prop-types";
import { compose, withProps, withState } from "recompose";

import { Form } from "@dpdgroupuk/mydpd-ui";

import styles from "./FileInput.module.scss";

const FileInput = ({
  classes,
  filename,
  input,
  label,
  meta,
  onFileChange,
  ...rest
}) => (
  <div className={classNames(styles.container, classes.container)}>
    <input
      {...rest}
      type="file"
      id="upload"
      className={classNames(styles.fileInput)}
      onChange={onFileChange}
    />
    <Form.MaterialTextInput
      classes={{
        group: classes.group,
      }}
      input={{ ...input, value: filename }}
      label={label}
      meta={meta}
      name={"filename"}
      helperText={true}
    />
    <label
      className={classNames(styles.fileInputButton, classes.button)}
      htmlFor="upload"
    >
      select file
    </label>
  </div>
);

FileInput.defaultProps = {
  type: "text",
  multiline: false,
  required: false,
};

FileInput.propTypes = {
  label: PropTypes.string,
  accept: PropTypes.any,
  filename: PropTypes.string,
  multiline: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
  meta: PropTypes.object,
  input: PropTypes.object,
  classes: PropTypes.object,
  onFileChange: PropTypes.func,
  setFilename: PropTypes.func,
};

export default compose(
  withState("filename", "setFilename", ""),
  withProps(({ setFilename, filename, input, onChange }) => ({
    onFileChange: async e => {
      const targetFile = e.target.files[0];
      if (targetFile) {
        input.onChange(targetFile);
        setFilename(targetFile.name);
      } else if (!targetFile && filename.length === 0) {
        input.onChange(null);
        setFilename("");
      }
      onChange && onChange(e);
    },
  }))
)(FileInput);
