import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { apisClient } from "~/apis";

export const ActionTypes = createActionTypes("ADDRESS_LOOKUP", {
  SEARCH: createAsyncActionTypes("SEARCH"),
});

export const search = createAsyncAction(
  (data, options) =>
    apisClient.reference
      .searchAddresses(
        {
          pageSize: 50,
          ...data,
        },
        options
      )
      .then(({ data }) => data),
  ActionTypes.SEARCH
);
