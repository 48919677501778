import { get } from "lodash";

import { createPropsSelectorOrm, createSelectorOrm } from "../utils";

export const getAddressBooks = createSelectorOrm(session =>
  session.AddressBook.all().toRefArray()
);

export const getAddressBook = createPropsSelectorOrm((session, addressBookId) =>
  get(session.AddressBook.withId(addressBookId), "ref")
);
