export const UNSUPPORTED_NETWORKS = [
  "1^00",
  "1^03",
  "1^04",
  "1^05",
  "1^15",
  "1^22",
  "1^35",
  "1^39",
  "1^50",
  "1^58",
  "1^59",
  "1^60",
  "1^79",
  "1^80",
  "1^89",
  "1^91",
  "1^93",
  "1^98",
  "1^99",
  "2^00",
  "2^22",
  "2^50",
  "2^57",
  "2^58",
  "2^60",
  "2^80",
  "2^91",
  "2^99",
];

export const excludeUnsupportedNetworks = (networks, serviceExclusions = []) =>
  networks.filter(
    ({ networkCode }) =>
      ![...UNSUPPORTED_NETWORKS, ...serviceExclusions].includes(networkCode)
  );
