import { get } from "lodash";
import { getFormSyncErrors, getFormValues } from "redux-form";
import { createSelector } from "reselect";

import {
  ADDRESS_BOOK_FORM,
  AddressBookEntity,
  AddressBookExportFields,
  AddressBookImportFields,
  BOOK_EXPORT_FORM,
  BOOK_IMPORT_FORM,
  BOOK_SEARCH_FORM,
  Fields,
  ImportOptionsValue,
} from "~/constants/forms";
import { GB } from "~/constants/strings";
import { ADDRESS_BOOK_TYPES } from "~/models/enum";
import { AddressBookSelectors } from "~/redux/orm";
import { ReferenceDataSelectors } from "~/redux/reference";
import { UmsSelectors } from "~/redux/ums";
import { getValue } from "~/utils/object";

import * as model from "./models";

export const getAddressBookValues = createSelector(
  getFormValues(ADDRESS_BOOK_FORM),
  values => values
);

export const getAddressbookType = createSelector(
  getAddressBookValues,
  address => get(address, "addressType")
);

export const getCountries = createSelector(
  ReferenceDataSelectors.getCountries,
  model.mapCountriesToKeyValue
);

export const isUserReadonlyPermissions = createSelector(
  UmsSelectors.getPreferences,
  preferences =>
    !!get(
      preferences,
      "advancedCustomerConfiguration.readOnlyAddressBook",
      false
    )
);

export const getData = state => state.ui.addressBook;

export const getAddressBooks = AddressBookSelectors.getAddressBooks;

export const getAddressBooksTotalResults = createSelector(
  getData,
  ({ totalResults }) => totalResults
);

export const getImportAddressBooksErrors = createSelector(
  getData,
  ({ importErrors }) => importErrors
);

export const getTemplateValue = createSelector(
  getFormValues(BOOK_IMPORT_FORM),
  values => values[AddressBookImportFields.TEMPLATE]
);

export const getExportTemplateValue = createSelector(
  getFormValues(BOOK_EXPORT_FORM),
  values => values[AddressBookExportFields.TEMPLATE]
);

export const getImportOptionValue = createSelector(
  getFormValues(BOOK_IMPORT_FORM),
  values => values[AddressBookImportFields.IMPORT_OPTIONS]
);

export const groupImportBehaviorIsDisabled = createSelector(
  getFormValues(BOOK_IMPORT_FORM),
  (values = {}) => {
    if (
      values[AddressBookImportFields.IMPORT_OPTIONS] ===
        ImportOptionsValue.IMPORT_NEW_ONLY ||
      values[AddressBookImportFields.IMPORT_OPTIONS] ===
        ImportOptionsValue.EMPTY
    ) {
      return true;
    }

    if (
      values[AddressBookImportFields.IMPORT_OPTIONS] ===
        ImportOptionsValue.OVERWRITE &&
      values[AddressBookImportFields.TEMPLATE] === "1"
    ) {
      return true;
    }
  }
);

export const getAddressType = createSelector(getAddressBookValues, values =>
  get(values, "addressType", "")
);

export const getCountryCode = createSelector(getAddressBookValues, values =>
  get(values, "address.countryCode", "")
);

export const getSelectedCountry = createSelector(
  getCountryCode,
  getCountries,
  model.getCountryByCountryCode
);

export const getRequiredFields = createSelector(
  getAddressBookValues,
  values => {
    const countryCode = get(values, "address.countryCode", null);

    return {
      [AddressBookEntity.SHORT_NAME]: true,
      [AddressBookEntity.ADDRESS.POSTCODE]: countryCode === GB,
      [AddressBookEntity.ADDRESS.COUNTRY_CODE]: true,
      [AddressBookEntity.ADDRESS.STREET]: true,
      [AddressBookEntity.ADDRESS.TOWN]: true,
      [AddressBookEntity.ADDRESS_TYPE]: true,
    };
  }
);

export const getSearchFormErrors = createSelector(
  getFormSyncErrors(BOOK_SEARCH_FORM),
  errors => errors
);

// @see: https://it.dpduk.live/version/customer-shipping/ni-protocol-shipping/sprint-1/diag_7dsSbvGGAqCIPVhB.html
export const getAllowedFields = createSelector(
  getFormValues(ADDRESS_BOOK_FORM),
  getAddressbookType,
  (values, addressType) => {
    const isBusiness = getValue(values, AddressBookEntity.IS_BUSINESS, false);
    const isReturn =
      getValue(
        values,
        AddressBookEntity.ADDRESS_TYPE,
        ADDRESS_BOOK_TYPES.DELIVERY
      ) === ADDRESS_BOOK_TYPES.RETURN;
    const isUnitedKingdom =
      getValue(values, AddressBookEntity.ADDRESS.COUNTRY_CODE) === GB;

    return {
      notifications: addressType === ADDRESS_BOOK_TYPES.DELIVERY,
      [Fields.IS_DEFAULT]: addressType === ADDRESS_BOOK_TYPES.RETURN,
      [Fields.IS_BUSINESS]: isBusiness,
      [Fields.EORI_NUMBER]: isBusiness || isReturn,
      [Fields.VAT_NUMBER]: isBusiness || isReturn,
      [Fields.AT_RISK]: isBusiness && isUnitedKingdom,
      [Fields.ADDRESS_TYPE]: !isReturn,
      [Fields.UKIMS_NUMBER]:
        isBusiness &&
        getValue(values, AddressBookEntity.ADDRESS.COUNTRY_CODE, "") === GB,
    };
  }
);
