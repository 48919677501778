import {
  createActionTypes,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

export default createActionTypes("ADDRESS_BOOK", {
  SEARCH: createAsyncActionTypes("SEARCH"),
  FETCH: createAsyncActionTypes("FETCH"),
  DELETE_ALL: createAsyncActionTypes("DELETE_ALL"),
  CREATE: createAsyncActionTypes("CREATE"),
  IMPORT: createAsyncActionTypes("IMPORT"),
  CLEAR_IMPORT_ERRORS: "CLEAR_IMPORT_ERRORS",
  UPDATE: createAsyncActionTypes("UPDATE"),
  DELETE: createAsyncActionTypes("DELETE"),
});
