import fp from "lodash/fp";

export const onlyStrings = fp.compose(
  fp.filter(fp.isString),
  fp.filter(fp.negate(fp.isEmpty))
);

export const joinStringsWithComma = fp.compose(fp.join(", "), onlyStrings);

export const formatMessage = (template, ...args) =>
  template.replace(/{(\d)}/g, (s, num) => args[num]);
