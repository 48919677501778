import classNames from "classnames";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Field, propTypes } from "redux-form";

import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { ReduxifiedDragAndDrop } from "~/components/DragAndDrop";
import Step from "~/components/Step";
import { ADDRESS_BOOK_ENTRY_PAGE } from "~/constants/analytics";
import { AddressBookEntity } from "~/constants/forms";
import * as S from "~/constants/strings";
import { ADDRESS_BOOK_TYPES } from "~/models/enum";
import { GroupsSelectors } from "~/redux/orm";
import { ADDRESSES } from "~/router";

import styles from "./ValidationAndAssociatedGroups.module.scss";

const Row = ({ label, input, disabled }) => (
  <div
    className={classNames(
      styles.validationRow,
      disabled && styles.validationRowDisabled
    )}
  >
    <div>{label}</div>
    <div>{input.value}</div>
  </div>
);

Row.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  disabled: PropTypes.bool,
};

const ValidationAndAssociatedGroups = props => {
  const {
    initialized,
    addressType,
    isReadonly,
    isDisabled,
    addressBookGroups,
  } = props;

  return (
    <Step
      classes={{
        container: styles.container,
      }}
      step={3}
      title={S.VALIDATION_AND_ASSOCIATED_GROUPS}
    >
      <div className={styles.inner}>
        <div className={styles.validationContainer}>
          <Field
            disabled={!initialized || isDisabled}
            name={AddressBookEntity.IS_VALID}
            component={Row}
            type="text"
            label={S.ADDRESS_VALID}
            format={v =>
              !initialized || isDisabled || v === undefined
                ? ""
                : v
                ? "Yes"
                : "No"
            }
          />
          <Field
            disabled={!initialized || isDisabled}
            name={AddressBookEntity.AUDIT_INFO.CREATED_DATE}
            component={Row}
            type="text"
            label={S.DATE_CREATED}
            format={date =>
              date && moment(date, "YYYY-MM-DD").format("DD-MM-YYYY")
            }
          />
          <Field
            disabled={!initialized || isDisabled}
            name={AddressBookEntity.AUDIT_INFO.OWNER}
            component={Row}
            type="text"
            label={S.OWNER}
          />
          <Field
            disabled={!initialized || isDisabled}
            name={AddressBookEntity.AUDIT_INFO.UPDATED_DATE}
            component={Row}
            type="text"
            label={S.LAST_UPDATED}
            format={date =>
              date && moment(date, "YYYY-MM-DD").format("DD-MM-YYYY")
            }
          />
        </div>
        {addressType === ADDRESS_BOOK_TYPES.DELIVERY && (
          <Field
            disabled={isReadonly || !initialized || isDisabled}
            name={AddressBookEntity.GROUPS}
            component={ReduxifiedDragAndDrop}
            type="text"
            values={addressBookGroups}
            onSourceChange={props.onSourceChange}
            onDestinationChange={props.onDestinationChange}
            format={values =>
              values
                ? values.map(({ groupId, groupName }) => ({
                    key: groupId,
                    value: groupName,
                  }))
                : []
            }
            normalize={values =>
              values
                ? values.map(({ key, value }) => ({
                    groupId: key,
                    groupName: value,
                  }))
                : []
            }
          />
        )}
      </div>
    </Step>
  );
};

ValidationAndAssociatedGroups.propTypes = {
  ...propTypes,
  addressBookGroups: PropTypes.arrayOf(PropTypes.object),
  isReadonly: PropTypes.bool,
};

export default compose(
  connect((state, { location }) => ({
    addressBookGroups:
      location.pathname === ADDRESSES
        ? []
        : GroupsSelectors.getAddressBookGroupsKeyValue(state),
  })),
  withTrackProps({
    onSourceChange: ADDRESS_BOOK_ENTRY_PAGE.ON_SELECT_ASSOCIATED_GROUPS,
    onDestinationChange: ADDRESS_BOOK_ENTRY_PAGE.ON_SELECT_AVAILABLE_GROUPS,
  })
)(ValidationAndAssociatedGroups);
