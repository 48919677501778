import { AddressBookActionTypes } from "~/redux/orm";

import { getImportErrors } from "./models";

export const initialState = {
  totalResults: 0,
  importErrors: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AddressBookActionTypes.CLEAR_IMPORT_ERRORS:
    case AddressBookActionTypes.IMPORT.REQUEST: {
      return {
        ...state,
        importErrors: [],
      };
    }
    case AddressBookActionTypes.IMPORT.SUCCESS: {
      return {
        ...state,
        importErrors: getImportErrors(action.payload),
      };
    }
    case AddressBookActionTypes.SEARCH.SUCCESS: {
      return {
        ...state,
        totalResults: action.payload.totalResults,
      };
    }
    case AddressBookActionTypes.DELETE.SUCCESS: {
      return {
        ...state,
        totalResults: state.totalResults - 1,
      };
    }
    case AddressBookActionTypes.CREATE.SUCCESS: {
      return {
        ...state,
        totalResults: state.totalResults + 1,
      };
    }
    case AddressBookActionTypes.DELETE_ALL.SUCCESS: {
      return {
        ...state,
        totalResults: 0,
      };
    }
    default:
      return state;
  }
};
