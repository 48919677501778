import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Field, reduxForm } from "redux-form";

import { withNotifier } from "@dpdgroupuk/mydpd-app";
import {
  Button,
  Form,
  Modal,
  withOverlay,
  withSnackbar,
} from "@dpdgroupuk/mydpd-ui";
import {
  trackProps,
  withTrack,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import { EXPORT_ADDRESS_BOOK_MODAL } from "~/constants/analytics";
import {
  AddressBookExportFields,
  BOOK_EXPORT_FORM,
  TemplateList,
  TemplateType,
} from "~/constants/forms";
import {
  ADDRESS_BOOK_EXPORT,
  CANCEL,
  EXPORT,
  EXPORT_ADDRESS_BOOK_STARTED_PLEASE_USE,
  EXPORT_IS_ALREADY_IN_PROGRESS_PLEASE_USE,
  EXPORTING_ADDRESS_BOOK,
  PLEASE_SELECT_ADDRESS_BOOK,
  TEMPLATE,
  UPPERCASE,
} from "~/constants/strings";
import { AddressBookActions, ExportAddressBookActions } from "~/redux";

import { getExportTemplateValue } from "../../selectors";
import styles from "./ExportModal.module.scss";

const ExportModal = ({
  onClose,
  show,
  onChangeTemplate,
  onSubmit,
  handleSubmit,
  submitting,
}) => (
  <Modal
    dialogClassName={classNames(styles.modal)}
    show={show}
    onHide={onClose}
  >
    <Modal.Header>{ADDRESS_BOOK_EXPORT}</Modal.Header>
    <Modal.Body className={classNames(styles.modalBody)}>
      <Container className="p-0" fluid>
        <Row className={classNames(styles.row)}>
          <Col className={classNames(styles.column)}>
            <p className={classNames(styles.subHeader, "mb-4")}>
              {PLEASE_SELECT_ADDRESS_BOOK}
            </p>
          </Col>
        </Row>
        <Row className={classNames(styles.row)}>
          <Col className={classNames(styles.column)}>
            <Field
              component={Form.MaterialDropdown}
              label={TEMPLATE}
              name={AddressBookExportFields.TEMPLATE}
              values={TemplateList}
              onChange={onChangeTemplate}
              hideInitial={true}
              textTransform={UPPERCASE}
            />
          </Col>
        </Row>
      </Container>
    </Modal.Body>
    <Modal.Footer className={styles.bottomContainer}>
      {!submitting && (
        <>
          <Button variant="dark" onClick={onClose}>
            {CANCEL}
          </Button>
          <Button variant="primary" onClick={handleSubmit(onSubmit)}>
            {EXPORT}
          </Button>
        </>
      )}
      {!!submitting && (
        <ProgressBar
          animated
          now={100}
          label={EXPORTING_ADDRESS_BOOK}
          className={styles.progress}
        />
      )}
    </Modal.Footer>
  </Modal>
);

ExportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  templateValue: PropTypes.string,
  onChangeTemplate: PropTypes.func,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  onHide: PropTypes.func,
};

ExportModal.defaultProps = {
  onHide: () => null,
};

export default compose(
  withNotifier,
  withSnackbar,
  withOverlay,
  reduxForm({
    form: BOOK_EXPORT_FORM,
    initialValues: {
      [AddressBookExportFields.TEMPLATE]: TemplateType.DEFAULT,
    },
  }),
  connect(
    state => ({
      templateValue: getExportTemplateValue(state),
      errors: [],
    }),
    (dispatch, { snackbar }) => ({
      onSubmit: async (value, dispatch, { onClose, overlay }) => {
        try {
          overlay.show();
          await dispatch(
            ExportAddressBookActions.exportAddressBook({
              addressBookType: value.type,
            })
          );
          snackbar.showInfo({
            message: EXPORT_ADDRESS_BOOK_STARTED_PLEASE_USE,
          });
        } catch (err) {
          snackbar.showWarning({
            message: EXPORT_IS_ALREADY_IN_PROGRESS_PLEASE_USE,
          });
        } finally {
          overlay.hide();
          onClose();
        }
      },
      clearErrors: () => dispatch(AddressBookActions.clearImportErrors()),
    })
  ),
  withTrack(trackProps(EXPORT_ADDRESS_BOOK_MODAL)),
  withTrackProps({
    onChangeTemplate: EXPORT_ADDRESS_BOOK_MODAL.SELECT_TEMPLATE,
    onClose: EXPORT_ADDRESS_BOOK_MODAL.CLICK_CLOSE,
    onSubmit: EXPORT_ADDRESS_BOOK_MODAL.CLICK_EXPORT,
  })
)(ExportModal);
