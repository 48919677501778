import { Redirect, Route } from "react-router";

import { NotFoundHandleSwitch } from "@dpdgroupuk/mydpd-app";

import AddressBook from "~/pages/AddressBook";
import ExportAddressBook from "~/pages/ExportAddressBook";
import Group from "~/pages/Group";

import * as routes from "./constants";

export const Routes = () => (
  <NotFoundHandleSwitch>
    <Redirect exact from="/" to={routes.ADDRESSES} />
    <Route path={`${routes.ADDRESSES}/:id?`} component={AddressBook} />
    <Route path={`${routes.GROUPS}/:groupId?`} component={Group} />
    <Route path={`${routes.EXPORTS}`} component={ExportAddressBook} />
  </NotFoundHandleSwitch>
);
