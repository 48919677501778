import { GroupsActions } from "~/redux/orm";

export const fetchGroups = GroupsActions.fetchGroups;

export const fetchGroupById = GroupsActions.fetchGroupById;

export const fetchGroupAddressesById = GroupsActions.fetchGroupAddressesById;

export const deleteGroupById = GroupsActions.deleteGroupById;

export const updateGroup = GroupsActions.updateGroup;
