import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import { AppActionTypes } from "@dpdgroupuk/mydpd-app";

import { sentryReduxEnhancer } from "../sentry";
import rootReducer from "./reducers";

const middlewares = [thunk];

if (process.env.NODE_ENV !== "production") {
  middlewares.push(require("redux-logger").logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore((state, action) => {
  if (action.type === AppActionTypes.ACTIONS.CLEAR) {
    state = undefined;
  }
  return rootReducer(state, action);
}, composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer));
