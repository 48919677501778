import { get } from "lodash";

import { createPropsSelectorOrm, createSelectorOrm } from "../utils";

export const getAddressBookGroups = createSelectorOrm(session =>
  session.AddressBookGroup.all().toRefArray()
);

export const getAddressBookGroup = createPropsSelectorOrm((session, groupId) =>
  get(session.AddressBookGroup.withId(groupId), "ref")
);

export const getGroupAddresses = createPropsSelectorOrm((session, groupId) => {
  const value = get(session.AddressBookGroup.withId(groupId), "ref", {});
  return value.addresses;
});

export const getAddressBookGroupsKeyValue = createSelectorOrm(session => {
  const groups = session.AddressBookGroup.all().toRefArray();
  return groups.map(({ groupId, groupName }) => ({
    key: groupId,
    value: groupName,
  }));
});
