import { createAsyncAction } from "@dpdgroupuk/redux-action-creator";

import { addressBookExporterApi } from "~/apis";

import ActionTypes from "./actionTypes";

export const searchExportAddressBooks = createAsyncAction(
  async (query, fetchOptions) => {
    const { data } = await addressBookExporterApi.fetchExportAddressBooks(
      query,
      fetchOptions
    );
    return data;
  },
  ActionTypes.SEARCH
);

export const getDownloadFileUrl = createAsyncAction(async (params, options) => {
  const { data } =
    await addressBookExporterApi.generateExportAddressBookFileUrl(
      params,
      options
    );

  return {
    fileUrl: data.fileUrl,
    ...params,
  };
}, ActionTypes.GENERATE_FILE_URL);

export const exportAddressBook = createAsyncAction(async (params, options) => {
  const { data } = await addressBookExporterApi.exportAddressBook(
    params,
    options
  );

  return {
    ...data,
    ...params,
  };
}, ActionTypes.EXPORT_ADDRESS_BOOK);

export const refreshAddressBook = createAsyncAction(async (params, options) => {
  const { data } = await addressBookExporterApi.refreshAddressBook(
    params,
    options
  );

  return data;
}, ActionTypes.GET_EXPORTER_ADDRESS_BOOK);
