import PropTypes from "prop-types";

const Refresh = ({ classNameSvg, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNameSvg}
    {...props}
  >
    <path
      d="M5.82853 15.4043C5.55524 14.876 5.34883 14.3306 5.2093 13.7681C5.06977 13.2055 5 12.6318 5 12.0469C5 10.0168 5.67973 8.28911 7.0392 6.86372C8.39868 5.43834 10.0523 4.72564 12 4.72564H12.7053L11.0343 2.98915L11.9861 2L15.2863 5.42959L11.9861 8.85918L11.0343 7.87003L12.7053 6.13354H12C10.4309 6.13354 9.09779 6.70755 8.00059 7.85557C6.9034 9.0036 6.3548 10.4007 6.3548 12.0469C6.3548 12.4356 6.39562 12.824 6.47725 13.2121C6.55889 13.6002 6.68134 13.9789 6.84462 14.3484L5.82853 15.4043ZM12.0139 22L8.71367 18.5704L12.0139 15.1408L12.9657 16.13L11.2947 17.8665H12C13.5691 17.8665 14.9022 17.2924 15.9994 16.1444C17.0966 14.9964 17.6452 13.5993 17.6452 11.9531C17.6452 11.5644 17.6044 11.176 17.5227 10.7879C17.4411 10.3998 17.3187 10.0211 17.1554 9.65162L18.1715 8.59568C18.4448 9.12396 18.6512 9.66938 18.7907 10.2319C18.9302 10.7945 19 11.3682 19 11.9531C19 13.9832 18.3203 15.7109 16.9608 17.1363C15.6013 18.5617 13.9477 19.2744 12 19.2744H11.2947L12.9657 21.0108L12.0139 22Z"
      fill="#5D5D5D"
    />
  </svg>
);

Refresh.propTypes = {
  classNameSvg: PropTypes.string,
};

export default Refresh;
