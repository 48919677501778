import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose, withProps, withState } from "recompose";
import { Field, reduxForm, SubmissionError } from "redux-form";

import { withNotifier } from "@dpdgroupuk/mydpd-app";
import { Button, Form, Modal, withSnackbar } from "@dpdgroupuk/mydpd-ui";
import {
  trackProps,
  withTrack,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import FileInput from "~/components/FileInput/FileInput";
import RadioInput from "~/components/RadioInput";
import { IMPORT_ADDRESS_BOOK_MODAL } from "~/constants/analytics";
import {
  AddressBookImportFields,
  BOOK_IMPORT_FORM,
  DEFAULT_FILE_EXTENSIONS,
  GroupImportBehaviorValue,
  ImportOptionsValue,
  TemplateList,
  TemplateType,
} from "~/constants/forms";
import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import {
  ADDRESS_BOOK_IMPORT,
  CANCEL,
  DEFAULT,
  EMPTY_ADDRESS,
  FILE_LOCATION,
  GROUP_IMPORT_BEHAVIOUR,
  IMPORT_NEW_ONLY,
  IMPORT_OPTIONS,
  OVERWRITE_ADDRESSES,
  TEMPLATE,
  UPDATE,
  UPLOAD,
  UPLOAD_FILE_CHANGED,
  UPLOADING_ADDRESS_BOOK,
  UPPERCASE,
} from "~/constants/strings";
import { importBookValidators } from "~/models/validators";
import { AddressBookActions, GroupsActions } from "~/redux";

import {
  getImportAddressBooksErrors,
  getImportOptionValue,
  getTemplateValue,
  groupImportBehaviorIsDisabled,
} from "../../selectors";
import styles from "./ImportModal.module.scss";

const ImportModal = ({
  onCloseModal,
  show,
  groupImportBehaviorIsDisabled,
  onChangeImportOptions,
  onChangeGroupImportBehavior,
  onChangeTemplate,
  onFileChange,
  onSubmit,
  handleSubmit,
  submitting,
}) => (
  <Modal
    dialogClassName={classNames(styles.modal)}
    show={show}
    onHide={onCloseModal}
  >
    <Modal.Header>{ADDRESS_BOOK_IMPORT}</Modal.Header>
    <Modal.Body className={classNames(styles.modalBody)}>
      <Container className="p-0" fluid>
        <Row className={classNames(styles.row)}>
          <Col sm={3} className={classNames(styles.column)}>
            <p className={classNames(styles.subHeader)}>{TEMPLATE}</p>
          </Col>
          <Col sm={9} className={classNames(styles.column)}>
            <Field
              component={Form.MaterialDropdown}
              label={TEMPLATE}
              name={AddressBookImportFields.TEMPLATE}
              values={TemplateList}
              onChange={onChangeTemplate}
              hideInitial={true}
              textTransform={UPPERCASE}
            />
          </Col>
        </Row>
        <Row className={classNames(styles.row)}>
          <Col sm={3} className={classNames(styles.column)}>
            <p className={classNames(styles.subHeader)}>{FILE_LOCATION}</p>
          </Col>

          <Col sm={9} className={classNames(styles.column)}>
            <Field
              classes={{
                group: classNames(styles.fileInput),
                container: styles.fileInputContainer,
              }}
              component={FileInput}
              label={FILE_LOCATION}
              accept={DEFAULT_FILE_EXTENSIONS}
              name={AddressBookImportFields.ADDRESS_BOOK_FILE}
              onChange={onFileChange}
            />
          </Col>
        </Row>
        <Row className={classNames(styles.row)}>
          <Col sm={3} className={classNames(styles.column)}>
            <p className={classNames(styles.subHeader)}>{IMPORT_OPTIONS}</p>
          </Col>
          <Col sm={9} className={classNames(styles.column)}>
            <Field
              classNameLabel={classNames(styles.radioInput)}
              component={RadioInput}
              label={IMPORT_NEW_ONLY}
              name={AddressBookImportFields.IMPORT_OPTIONS}
              value={ImportOptionsValue.IMPORT_NEW_ONLY}
              type="radio"
              onChange={onChangeImportOptions}
            />
            <Field
              classNameLabel={classNames(styles.radioInput)}
              component={RadioInput}
              label={OVERWRITE_ADDRESSES}
              name={AddressBookImportFields.IMPORT_OPTIONS}
              value={ImportOptionsValue.OVERWRITE}
              type="radio"
              onChange={onChangeImportOptions}
            />
            <Field
              classNameLabel={classNames(styles.radioInput)}
              component={RadioInput}
              label={EMPTY_ADDRESS}
              name={AddressBookImportFields.IMPORT_OPTIONS}
              value={ImportOptionsValue.EMPTY}
              type="radio"
              onChange={onChangeImportOptions}
            />
          </Col>
        </Row>
        <Row className={classNames(styles.row)}>
          <Col sm={3} className={classNames(styles.column)}>
            <p className={classNames(styles.subHeader)}>
              {GROUP_IMPORT_BEHAVIOUR}
            </p>
          </Col>
          <Col sm={9} className={classNames(styles.column)}>
            <Field
              classNameLabel={classNames(styles.radioInput)}
              component={RadioInput}
              label={DEFAULT}
              value={GroupImportBehaviorValue.DEFAULT}
              type="radio"
              disabled={groupImportBehaviorIsDisabled}
              name={AddressBookImportFields.GROUP_IMPORT}
              onChange={onChangeGroupImportBehavior}
            />
            <Field
              name={AddressBookImportFields.GROUP_IMPORT}
              label={UPDATE}
              component={RadioInput}
              type="radio"
              disabled={groupImportBehaviorIsDisabled}
              value={GroupImportBehaviorValue.UPDATE}
              classNameLabel={classNames(styles.radioInput)}
              onChange={onChangeGroupImportBehavior}
            />
          </Col>
        </Row>
      </Container>
    </Modal.Body>
    <Modal.Footer className={styles.bottomContainer}>
      {!submitting && (
        <>
          <Button variant="dark" onClick={onCloseModal}>
            {CANCEL}
          </Button>
          <Button variant="primary" onClick={handleSubmit(onSubmit)}>
            {UPLOAD}
          </Button>
        </>
      )}
      {!!submitting && (
        <ProgressBar
          animated
          now={100}
          label={UPLOADING_ADDRESS_BOOK}
          className={styles.progress}
        />
      )}
    </Modal.Footer>
  </Modal>
);

ImportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  groupImportBehaviorIsDisabled: PropTypes.bool,
  submitting: PropTypes.bool,
  templateValue: PropTypes.string,
  onChangeImportOptions: PropTypes.func,
  onFileChange: PropTypes.func,
  onChangeGroupImportBehavior: PropTypes.func,
  onChangeTemplate: PropTypes.func,
  onCloseModal: PropTypes.func,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  onHide: PropTypes.func,
};

ImportModal.defaultProps = {
  onHide: () => null,
};

export default compose(
  withState("reload", "setReload", false),
  withNotifier,
  withSnackbar,
  reduxForm({
    form: BOOK_IMPORT_FORM,
    validate: importBookValidators.importBook,
    initialValues: {
      [AddressBookImportFields.IMPORT_OPTIONS]:
        ImportOptionsValue.IMPORT_NEW_ONLY,
      [AddressBookImportFields.TEMPLATE]: TemplateType.DEFAULT,
      [AddressBookImportFields.GROUP_IMPORT]: GroupImportBehaviorValue.DEFAULT,
    },
  }),
  connect(
    state => ({
      groupImportBehaviorIsDisabled: groupImportBehaviorIsDisabled(state),
      templateValue: getTemplateValue(state),
      errors: getImportAddressBooksErrors(state),
      importValue: getImportOptionValue(state),
    }),
    (dispatch, { notifier, setReload, snackbar }) => ({
      onSubmit: notifier.runAsync(
        async (values, dispatch, { onClose, onSubmitImport }) => {
          // If file has been changed and you will try to upload - Chrome will throw an error "ERR_UPLOAD_FILE_CHANGED"
          try {
            await values[AddressBookImportFields.ADDRESS_BOOK_FILE]
              .slice(0, 1) // only the first byte
              .arrayBuffer();
          } catch (error) {
            return snackbar.showAlert({
              message: UPLOAD_FILE_CHANGED,
              displayTime: SHOW_ALERT_DISPLAY_TIME,
            });
          }

          try {
            const { data } = await dispatch(
              AddressBookActions.importAddressBook(values)
            );
            setReload(false);
            onSubmitImport(data);
            onClose();
            dispatch(GroupsActions.fetchGroups());
          } catch (err) {
            setReload(values.updateType === ImportOptionsValue.EMPTY);

            const { code, message } = err[0] || {};
            if (message && code === 103) {
              notifier.scrollToError([err[0].fieldPath]);

              throw new SubmissionError({
                addressBookFile: message,
              });
            } else {
              throw err;
            }
          }
        }
      ),
      clearErrors: () => dispatch(AddressBookActions.clearImportErrors()),
    })
  ),
  withProps(({ change, clearErrors, onClose, reload }) => ({
    onChangeTemplate: () =>
      change(
        AddressBookImportFields.GROUP_IMPORT,
        GroupImportBehaviorValue.DEFAULT
      ),
    onCloseModal: () => {
      onClose(reload);
      clearErrors();
    },
    onChangeImportOptions: () =>
      change(
        AddressBookImportFields.GROUP_IMPORT,
        GroupImportBehaviorValue.DEFAULT
      ),
  })),
  withTrack(trackProps(IMPORT_ADDRESS_BOOK_MODAL)),
  withTrackProps({
    onChangeImportOptions: event => {
      const value = event.target.value;
      switch (value) {
        case ImportOptionsValue.IMPORT_NEW_ONLY:
          return IMPORT_ADDRESS_BOOK_MODAL.SELECT_IMPORT_NEW_ONLY;
        case ImportOptionsValue.OVERWRITE:
          return IMPORT_ADDRESS_BOOK_MODAL.SELECT_OVERWRITE_ADDRESSES;
        case ImportOptionsValue.EMPTY:
          return IMPORT_ADDRESS_BOOK_MODAL.SELECT_EMPTY_ADDRESS;
      }
    },
    onChangeGroupImportBehavior: event => {
      const value = event.target.value;
      switch (value) {
        case GroupImportBehaviorValue.DEFAULT:
          return IMPORT_ADDRESS_BOOK_MODAL.SELECT_GROUP_IMPORT_DEFAULT;
        case GroupImportBehaviorValue.UPDATE:
          return IMPORT_ADDRESS_BOOK_MODAL.SELECT_GROUP_IMPORT_UPDATE;
      }
    },
    onChangeTemplate: IMPORT_ADDRESS_BOOK_MODAL.SELECT_TEMPLATE,
    onFileChange: IMPORT_ADDRESS_BOOK_MODAL.CLICK_SELECT_FILE,
    onClose: IMPORT_ADDRESS_BOOK_MODAL.CLICK_CLOSE,
  })
)(ImportModal);
