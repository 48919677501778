import api from "./api";

export const fetchExportAddressBooks = (query, options) =>
  api.get({
    path: "/addressbook/export",
    query,
    options,
  });

export const generateExportAddressBookFileUrl = (query, options) =>
  api.post({
    path: "/addressbook/export/generateFileUrl",
    query,
    options,
  });

export const exportAddressBook = ({ addressBookType }, options) =>
  api.post({
    path: "/addressbook/export",
    query: { addressBookType },
    options,
  });

export const refreshAddressBook = ({ exportAddressBookId }, options) =>
  api.get({
    path: `/addressbook/export/${exportAddressBookId}`,
    options,
  });
